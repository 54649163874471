import React from 'react'
import Stepper from './common/ActivityProgressBar'
import * as RegistrationConstants from './common/Constants'
import VendorSelectionPanel from './VendorSelectionPanel'
import EdiSetup from './EdiPartnerInfoPanel'
import './Form.css'
import ServiceProviderSelectionPanel from './ServiceProviderSelectionPanel'
import RegistrationTypeSelectionPanel from './RegistrationTypeSelectionPanel'
import RegistrationConfirmation from './RegistrationConfirmationPanel'
import RegistrationSubmittedPanel from './RegistrationSubmittedPanel'
import BusinessPartnerContact from './BusinessPartnerContacts'
import ErrorBoundary from '../../global/util/ErrorBoundary'
import * as GlobalConstants from './common/Constants'
import * as BreadcrumbConstants from '../registration/common/BreadCrumbConstants'
import { Breadcrumbs } from '@enterprise-ui/canvas-ui-react'
import BeforeUnloadComponent from 'react-beforeunload-component'

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeStep: RegistrationConstants.REGISTRATION_STEP_1_ID,
      selectedVendor: '',
      vendorDetails: '',
      ediId: '',
      qualifier: '',
      selectedServiceProvider: '',
      selectedRegistrationType: '',
      userRegistration: [],
      userId: this.props.userInfo.lanId,
      testUserId: localStorage.getItem('testLoginUser'),
      inError: false,
      blockRoute: false,
    }

    this.RegistrationBody = this.RegistrationBody.bind(this)
    this.BusinessPartnerContact = this.BusinessPartnerContact.bind(this)
    this.EdiIdSetup = this.EdiIdSetup.bind(this)
    this.ServiceProvider = this.ServiceProvider.bind(this)
    this.RegistrationType = this.RegistrationType.bind(this)
    this.RegistrationConfirmation = this.RegistrationConfirmation.bind(this)
    this.RegistrationSubmitted = this.RegistrationSubmitted.bind(this)
    this.onVendorChange = this.onVendorChange.bind(this)
    this.onLoadVendorDetails = this.onLoadVendorDetails.bind(this)
    this.onVendorEdit = this.onVendorEdit.bind(this)

    this.onEdiInfoContinue = this.onEdiInfoContinue.bind(this)
    this.onEdiInfoEdit = this.onEdiInfoEdit.bind(this)

    this.handleServiceProviderChange =
      this.handleServiceProviderChange.bind(this)
    this.handleRegistrationTypeChange =
      this.handleRegistrationTypeChange.bind(this)
    this.resetServiceProvider = this.resetServiceProvider.bind(this)
    this.resetRegistrationTypeChange =
      this.resetRegistrationTypeChange.bind(this)
    this.handleEditConfirmation = this.handleEditConfirmation.bind(this)
    this.handleRegistrationConfirmation =
      this.handleRegistrationConfirmation.bind(this)
    this.onContactTypeChange = this.onContactTypeChange.bind(this)
    this.onContactTypeEdit = this.onContactTypeEdit.bind(this)
    this.handleException = this.handleException.bind(this)
  }

  handleException(exception) {
    this.setState({
      unexpectedError: true,
      exceptionDetails: exception,
    })
  }

  onVendorChange(selectedVendorValue) {
    if (selectedVendorValue) {
      this.setState({
        selectedVendor: selectedVendorValue,
      })
    }
  }

  onContactTypeChange(businessPartnerContacts) {
    if (businessPartnerContacts) {
      console.log(
        'Selected Business Partner Contact: ' +
          JSON.stringify(businessPartnerContacts)
      )
      var contactEmailAddress = ''
      for (const contact of businessPartnerContacts) {
        for (const emailAddress of contact.emailAddress) {
          contactEmailAddress = contactEmailAddress + emailAddress + ', '
        }
      }
      console.log('contact: ' + contactEmailAddress)
      this.setState({
        selectedBusinessPartnerContact: businessPartnerContacts,
        contactEmailAddress: contactEmailAddress,
      })

      if (businessPartnerContacts.length === 0) {
        this.setState({
          activeStep: RegistrationConstants.REGISTRATION_STEP_2_ID,
        })
      } else {
        if (
          this.state.activeStep === RegistrationConstants.REGISTRATION_STEP_2_ID
        ) {
          this.setState({
            activeStep: RegistrationConstants.REGISTRATION_STEP_3_ID,
          })
        }
      }
    }
  }

  onContactTypeEdit() {
    this.setState({
      activeStep: RegistrationConstants.REGISTRATION_STEP_2_ID,
      selectedBusinessPartnerContact: '',
      contactEmailAddress: '',
    })
  }

  onLoadVendorDetails(vendorDetails) {
    this.setState({
      isVendorDetailsLoaded: true,
      vendorDetails: vendorDetails,
      operatingCompany: vendorDetails.operatingCompany,
      blockRoute: true,
    })
    if (
      !vendorDetails.isTransportationVendor ||
      (vendorDetails.isTransportationVendor && !vendorDetails.isScacCodeNull)
    ) {
      this.setState({
        activeStep: RegistrationConstants.REGISTRATION_STEP_2_ID,
      })
    }
  }

  onVendorEdit() {
    this.setState({
      activeStep: RegistrationConstants.REGISTRATION_STEP_1_ID,
      selectedVendor: '',
    })
  }

  onEdiInfoContinue(ediId, qualifier, userRegistrationObj) {
    console.log(
      'EDIID setup validation success for values EdiId: ' +
        ediId +
        ' Qualifier: ' +
        qualifier +
        ' gmsVendor number: ' +
        this.state.vendorDetails.vendor.vendor_gvi.gms_vendor_id
    )
    this.setState({
      userRegistration: userRegistrationObj,
      ediId: ediId,
      qualifier: qualifier,
      activeStep: RegistrationConstants.REGISTRATION_STEP_3_ID,
    })
    if (
      userRegistrationObj &&
      userRegistrationObj.status &&
      userRegistrationObj.status.success
    ) {
      this.setState({
        activeStep: RegistrationConstants.REGISTRATION_STEP_4_ID,
      })
    }
  }

  onEdiInfoEdit() {
    this.setState({
      activeStep: RegistrationConstants.REGISTRATION_STEP_3_ID,
      userRegistration: null,
    })
  }

  handleServiceProviderChange(serviceProvider) {
    console.log('Selected ServiceProvider: ' + JSON.stringify(serviceProvider))
    this.setState({
      selectedServiceProvider: serviceProvider,
    })
    if (serviceProvider !== undefined) {
      this.setState({
        activeStep: RegistrationConstants.REGISTRATION_STEP_5_ID,
      })
    }
  }

  resetServiceProvider() {
    this.setState({
      activeStep: RegistrationConstants.REGISTRATION_STEP_4_ID,
      selectedServiceProvider: '',
    })
  }

  handleRegistrationTypeChange(registrationType) {
    console.log(
      'Selected Registration Type: ' + JSON.stringify(registrationType)
    )
    this.setState({
      activeStep: RegistrationConstants.REGISTRATION_STEP_6_ID,
      selectedRegistrationType: registrationType,
    })
  }

  resetRegistrationTypeChange() {
    this.setState({
      activeStep: RegistrationConstants.REGISTRATION_STEP_5_ID,
      selectedRegistrationType: '',
    })
  }

  handleEditConfirmation() {
    this.setState({
      activeStep: RegistrationConstants.REGISTRATION_STEP_5_ID,
    })
  }

  handleRegistrationConfirmation(registrationConfirmed) {
    if (registrationConfirmed) {
      this.setState({
        activeStep: RegistrationConstants.REGISTRATION_STEP_7_ID,
        blockRoute: false,
      })
    }
  }

  render() {
    if (this.state.unexpectedError) {
      throw new Error(this.state.exceptionDetails)
    }
    return (
      <BeforeUnloadComponent blockRoute={this.state.blockRoute}>
        <this.RegistrationBody />
      </BeforeUnloadComponent>
    )
  }

  RegistrationBody() {
    return (
      <div>
        <Breadcrumbs>
          <Breadcrumbs.Item href="/">
            {BreadcrumbConstants.HOME_CRUMB}
          </Breadcrumbs.Item>
          <Breadcrumbs.Item>
            {' '}
            {BreadcrumbConstants.PARTNER_REGISTRATION_CRUMB}
          </Breadcrumbs.Item>
        </Breadcrumbs>
        <div id="registration_form" style={{ paddingTop: '30px' }}>
          <Stepper step={this.state.activeStep} />
          <div
            style={{ paddingTop: '5px' }}
            className={
              this.state.activeStep >=
              RegistrationConstants.REGISTRATION_STEP_6_ID
                ? 'ess-content-hide'
                : ''
            }
          >
            <VendorSelectionPanel
              userid={
                this.state.testUserId !== undefined &&
                this.state.testUserId !== '' &&
                this.state.testUserId !== null
                  ? this.state.testUserId
                  : this.state.userId
              }
              userRoles={this.props.userRoles}
              onVendorChange={this.onVendorChange}
              onEdit={this.onVendorEdit}
              onLoadVendorDetails={this.onLoadVendorDetails}
              handleException={this.handleException}
              env={this.props.env}
            />
            <this.BusinessPartnerContact />
            <this.EdiIdSetup />
            <this.ServiceProvider />
            <this.RegistrationType />
          </div>
          <this.RegistrationConfirmation />
          <this.RegistrationSubmitted />
        </div>
      </div>
    )
  }

  EdiIdSetup() {
    if (this.state.activeStep >= RegistrationConstants.REGISTRATION_STEP_3_ID) {
      return (
        <ErrorBoundary>
          <EdiSetup
            env={this.props.env}
            handleContinue={this.onEdiInfoContinue}
            gmsVendorNumber={
              this.state.vendorDetails.vendor.vendor_gvi.gms_vendor_id
            }
            operatingCompany={this.state.vendorDetails.operatingCompany}
            companyId={this.state.vendorDetails.companyId}
            vendorName={this.state.vendorDetails.vendor.vendor_gvi.vendor_name}
            businessPartnerRoleId={
              this.state.vendorDetails.vendor.vendor_gvi.vendor_type
                .business_partner_role.business_partner_role_id
            }
            onEdit={this.onEdiInfoEdit}
            vendorId={
              this.state.vendorDetails.operatingCompany ===
              GlobalConstants.OPERATING_COMPANY_DVS
                ? this.state.vendorDetails.vendor.vendor_shippings[0]
                    .ecom_vendor_id
                : '0'
            }
            handleException={this.handleException}
            vendorDetails={this.state.vendorDetails}
            userRoles={this.props.userRoles}
          />
        </ErrorBoundary>
      )
    }
    return <React.Fragment></React.Fragment>
  }

  BusinessPartnerContact() {
    if (this.state.activeStep >= RegistrationConstants.REGISTRATION_STEP_2_ID) {
      return (
        <BusinessPartnerContact
          env={this.props.env}
          gmsVendorNumber={
            this.state.vendorDetails.vendor.vendor_gvi.gms_vendor_id
          }
          onContactTypeChange={this.onContactTypeChange}
          onEdit={this.onContactTypeEdit}
        />
      )
    }
    return <React.Fragment></React.Fragment>
  }

  ServiceProvider() {
    if (this.state.activeStep >= RegistrationConstants.REGISTRATION_STEP_4_ID) {
      return (
        <ServiceProviderSelectionPanel
          env={this.props.env}
          email={this.state.contactEmailAddress}
          handleServiceProviderChange={this.handleServiceProviderChange}
          resetServiceProvider={this.resetServiceProvider}
          onEdit={this.resetServiceProvider}
          operatingCompany={this.state.userRegistration.operatingCompany}
          ediId={this.state.ediId}
          qualifier={this.state.qualifier}
          vendorName={this.state.vendorDetails.vendor.vendor_gvi.vendor_name}
          handleException={this.handleException}
        />
      )
    }
    return <React.Fragment></React.Fragment>
  }

  RegistrationType() {
    if (this.state.activeStep >= RegistrationConstants.REGISTRATION_STEP_5_ID) {
      return (
        <RegistrationTypeSelectionPanel
          env={this.props.env}
          businessPartnerRoleId={
            this.state.vendorDetails.vendor.vendor_gvi.vendor_type
              .business_partner_role.business_partner_role_id
          }
          gmsVendorNumber={
            this.state.vendorDetails.vendor.vendor_gvi.gms_vendor_id
          }
          vendorId={
            this.state.vendorDetails.operatingCompany ===
            GlobalConstants.OPERATING_COMPANY_DVS
              ? this.state.vendorDetails.vendor.vendor_shippings[0]
                  .ecom_vendor_id
              : '0'
          }
          operatingCompany={this.state.userRegistration.operatingCompany}
          userRoles={this.props.userRoles}
          handleRegistrationTypeChange={this.handleRegistrationTypeChange}
          onEdit={this.resetRegistrationTypeChange}
          vendorDetails={this.state.vendorDetails}
          isTransportationVendor={
            this.state.vendorDetails.isTransportationVendor
          }
          companyId={this.state.vendorDetails.companyId}
        />
      )
    }
    return <React.Fragment></React.Fragment>
  }

  RegistrationConfirmation() {
    if (this.state.activeStep >= RegistrationConstants.REGISTRATION_STEP_6_ID) {
      return (
        <ErrorBoundary>
          <RegistrationConfirmation
            {...this.state}
            {...this.props}
            showSegmentInfo={true}
            operatingCompany={this.state.userRegistration.operatingCompany}
            registrationTypeId={
              this.state.selectedRegistrationType.registrationTypeId
            }
            handleEdit={this.handleEditConfirmation}
            handleConfirmationContinue={this.handleRegistrationConfirmation}
          />
        </ErrorBoundary>
      )
    }
    return <React.Fragment></React.Fragment>
  }

  RegistrationSubmitted() {
    if (this.state.activeStep >= RegistrationConstants.REGISTRATION_STEP_7_ID) {
      return (
        <React.Fragment>
          <RegistrationSubmittedPanel {...this.state} {...this.props} />
        </React.Fragment>
      )
    }
    return <React.Fragment></React.Fragment>
  }
}

export default RegistrationForm
