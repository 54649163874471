import React from 'react'
import axios from 'axios'
import { trackPromise } from 'react-promise-tracker'
import { RegistrationError } from '../registration/common/RegistrationError'
import * as moment from 'moment'
import '../../global/css/MaterialTable.css'
import RegistrationSearchResultsTable from './RegistrationStatusResultsTable'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Heading } from '@enterprise-ui/canvas-ui-react'

class RegistrationStatusResults extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      registrationSearchResults: [],
    }
    this.buildQueryParameters = this.buildQueryParameters.bind(this)
    this.loadSearchResults = this.loadSearchResults.bind(this)
  }

  /* Loads Registration Status Seach Results from Service */
  componentDidMount() {
    console.log(JSON.stringify(this.props))
    if (this.props.loadRequestFromCache === true) {
      this.setState({
        registrationSearchResults: JSON.parse(
          localStorage.getItem('registrationSearchResults')
        ),
        isLoading: false,
      })
    } else {
      this.loadSearchResults()
    }
  }

  loadSearchResults() {
    this.setState({
      isLoading: true,
      serviceError: false,
    })
    const startDate =
      moment(this.props.startDate).format('YYYY-MM-DD') + ' 00:00:00'
    const endDate =
      moment(this.props.endDate).format('YYYY-MM-DD') + ' 23:59:59'
    var REGISTRATION_SEARCH_ENDPOINT =
      this.props.env.ess.url +
      '/api/v1/registration-search?' +
      'operatingCompany=' +
      this.props.operatingCompany +
      '&endDate=' +
      endDate +
      '&startDate=' +
      startDate +
      this.buildQueryParameters()
    trackPromise(
      axios
        .get(REGISTRATION_SEARCH_ENDPOINT)
        .then((response) => {
          var data = response.data
          this.setState({
            registrationSearchResults: data,
            isLoading: false,
          })
          localStorage.setItem(
            'registrationSearchResults',
            JSON.stringify(data)
          )
        })
        .catch((error) => {
          console.log(
            'Unexpected error when doing registration search Error: ' + error
          )
          this.setState({ serviceError: true, isLoading: false })
        })
    )
  }

  buildQueryParameters() {
    var urlParams = ''
    Object.keys(this.props).map((key) => {
      if (
        key !== 'formSubmitted' &&
        key !== 'startDate' &&
        key !== 'endDate' &&
        key !== 'operatingCompany' &&
        key !== 'env'
      ) {
        if (this.props[key] !== undefined && this.props[key].length !== 0) {
          urlParams = urlParams + '&' + key + '=' + this.props[key]
        }
      }
      return urlParams
    })
    return urlParams
  }
  render() {
    return (
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Heading size={5}>Search Results</Heading>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
          <div id="contactsContent">
            {this.state.isLoading ? (
              'Data is loading...'
            ) : this.state.serviceError ? (
              <RegistrationError
                inError={this.state.serviceError}
                message="Error Message"
              />
            ) : this.state.registrationSearchResults &&
              this.state.registrationSearchResults.length > 0 ? (
              <div>
                <RegistrationSearchResultsTable
                  registrationSearchResults={
                    this.state.registrationSearchResults
                  }
                />
              </div>
            ) : (
              <RegistrationError
                inError={true}
                message="No registrations were found matching the supplied criteria."
              />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }
}

export default RegistrationStatusResults
