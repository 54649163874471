import React from 'react'
import axios from 'axios'
import { Grid, Anchor, Divider } from '@enterprise-ui/canvas-ui-react'

class HubbubPortalPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: this.props.email,
      isValidEmail: true,
    }
    this.handleHubbubLinkClick = this.handleHubbubLinkClick.bind(this)
  }

  handleHubbubLinkClick() {
    if (this.state.email) {
      this.setState({ isValidEmail: true })
      this.sendHubbubEmail(this.state.email)
      window.open(this.props.hubbublink, '_blank')
    } else {
      this.setState({ isValidEmail: false })
    }
  }

  sendHubbubEmail(emailId) {
    var hubbubRegistration = {}
    hubbubRegistration.emailId = emailId
    hubbubRegistration.hubbublink = this.props.hubbublink
    const requestOptions = {
      url: this.props.env.ess.url + '/api/v1/email-hubbub-registration',
      method: 'post',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(hubbubRegistration),
    }
    axios(requestOptions)
      .then((response) => {
        var data = response.data
        console.log('hubub registration email sent successfully: ' + data)
      })
      .catch((error) => {
        console.log(
          'Unexpected error occurred while calling the endpoint --> /api/v1/email-hubbub-registration Error: ' +
            error
        )
        this.setState({ error, isLoading: false })
      })
  }

  render() {
    return (
      <React.Fragment>
        <Divider width={1} />
        <div id="hubbub_portal">
          <Grid.Item xs={12}>
            <p className="hc-pt-dense hc-pb-expanded">
              Target’s EDI Communications are managed by our Service Provider,
              Loren Data.
            </p>
            <p className="hc-fs-section hc-blh-expanded ess-font-bold">
              Action Required!
            </p>
            <div className="hc-pl-expanded">
              <ul>
                <li className="hc-blh-expanded">
                  <p className="hc-pt-dense">
                    Based on the communication information provided, please go
                    to the{' '}
                    <span className="ess-font-bold">
                      <Anchor
                        onClick={this.handleHubbubLinkClick}
                        className="hubbub_portal_link"
                      >
                        Hubbub portal
                      </Anchor>
                    </span>{' '}
                    to establish an EDI connection.
                  </p>
                </li>
                <li className="hc-blh-expanded">
                  <p className="hc-pt-dense">
                    Any EDI IDs your company will be using will need to be set
                    up at both Hubbub and Partners Online.
                  </p>
                </li>
                <li className="hc-blh-expanded">
                  <p className="hc-pt-dense">
                    Once the EDI connection is set up at Hubbub, a confirmation
                    email will be sent.
                  </p>
                </li>
                <li className="hc-blh-expanded">
                  <p className="hc-pt-dense">
                    Save the email or the link from the email to your browser
                    favorites for future use and reference.
                  </p>
                </li>
                <li className="hc-blh-expanded">
                  <p className="hc-pt-dense hc-pb-expanded">
                    After the EDI connection set up is complete at Hubbub,
                    you’ll be redirected back to Partners Online to complete EDI
                    registration.
                  </p>
                </li>
              </ul>
            </div>
            <div className="hc-pt-dense hc-bg-grey07 hc-pb-dense ess-text-center ">
              <p className="hc-pb-dense hc-fs-section hc-blh-expanded">
                If you have any questions reach out to the following:
              </p>
              <p className="hc-pt-dense hc-pb-dense">
                EDI connection setup on the Hubbub portal, contact{' '}
                <span className="ess-font-bold">support@ecgrid.com</span>
              </p>
              <p>
                EDI Setup with Target, email{' '}
                <span className="ess-font-bold">EDI.helpdesk@target.com </span>{' '}
                or call <span className="ess-font-bold">612-304-3310</span>,
                option 1, M-F 7a-5p CT
              </p>
            </div>
          </Grid.Item>
        </div>
      </React.Fragment>
    )
  }
}

export default HubbubPortalPanel
