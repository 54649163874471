import React from 'react'
import axios from 'axios'
import { trackPromise } from 'react-promise-tracker'
import { RegistrationError } from '../../registration/common/RegistrationError'
import RegistrationSearchResultsTable from './RegistrationHistoryTable'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  Heading,
  Anchor,
  GridItem,
  GridContainer,
} from '@enterprise-ui/canvas-ui-react'

class RegistrationHistory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      registrationHistory: [],
    }
    this.loadRegistrationHistory = this.loadRegistrationHistory.bind(this)
  }

  /* Loads Registration Status Seach Results from Service */
  componentDidMount() {
    console.log(JSON.stringify(this.props))
  }

  loadRegistrationHistory() {
    this.setState({
      isLoading: true,
      serviceError: false,
      dataloaded: false,
    })

    var REGISTRATION_HISTORY_ENDPOINT =
      this.props.env.ess.url +
      '/api/v1/get-registration-history?' +
      'registrationGenId=' +
      this.props.registrationGenId
    trackPromise(
      axios
        .get(REGISTRATION_HISTORY_ENDPOINT)
        .then((response) => {
          var data = response.data
          this.setState({
            registrationHistory: data,
            isLoading: false,
          })
        })
        .catch((error) => {
          console.log(
            'Unexpected Error occurred while trying to access the get-registration-history Endpoint --> api/v1/get-registration-history?registrationGenId=' +
              this.props.registrationGenId +
              ' Error: ' +
              error
          )
          this.setState({ serviceError: true, isLoading: false })
        })
    )
  }

  render() {
    return (
      <Accordion defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => {
            if (!this.state.dataloaded) {
              this.loadRegistrationHistory()
            }
          }}
        >
          <Heading size={5}>Registration History</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <GridContainer>
            <GridItem xs={12}>
              <Anchor
                onClick={() => {
                  this.loadRegistrationHistory()
                }}
              >
                Refresh
              </Anchor>
            </GridItem>

            {this.state.isLoading ? (
              'Data is loading...'
            ) : this.state.serviceError ? (
              <RegistrationError
                inError={this.state.serviceError}
                message="Error Message"
              />
            ) : this.state.registrationHistory &&
              this.state.registrationHistory.length > 0 ? (
              <RegistrationSearchResultsTable
                registrationHistory={this.state.registrationHistory}
              />
            ) : (
              <RegistrationError
                inError={true}
                message="No registration activity history found for the registration."
              />
            )}
          </GridContainer>
        </AccordionDetails>
      </Accordion>
    )
  }
}

export default RegistrationHistory
