import React from 'react'
import {
  Heading,
  Grid,
  Divider,
  Button,
  Input,
} from '@enterprise-ui/canvas-ui-react'

class PurchaseOrderFormatSelection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedPurchaseOrderFormat: 'Basic',
      registrationConfirmed: false,
      confirmMode: false,
    }
    this.PurchaseOrderFormat = this.PurchaseOrderFormat.bind(this)
    this.confirmEditButton = this.confirmEditButton.bind(this)
    this.purchaseOrderFormatOptions = this.purchaseOrderFormatOptions.bind(this)
    this.selectedPurchaseOrderSummary =
      this.selectedPurchaseOrderSummary.bind(this)
    this.selectedPurchaseOrderVerbiage =
      this.selectedPurchaseOrderVerbiage.bind(this)
    this.handleConfirmationContinue = this.handleConfirmationContinue.bind(this)
  }

  handleConfirmationContinue() {
    if (this.props.selectedRegistrationType.poFormatFlag === 'N') {
      this.setState(
        {
          confirmMode: true,
          registrationConfirmed: true,
        },
        () =>
          this.props.handleConfirmationContinue(
            this.state.registrationConfirmed
          )
      )
    } else {
      if (this.props.selectedRegistrationType.poFormatFlag === 'Y') {
        if (this.state.selectedPurchaseOrderFormat === '') {
          this.setState({ confirmMode: true })
        } else {
          this.setState(
            {
              confirmMode: true,
              registrationConfirmed: true,
            },
            () =>
              this.props.handleConfirmationContinue(
                this.state.registrationConfirmed,
                this.state.selectedPurchaseOrderFormat
              )
          )
        }
      }
    }
  }

  PurchaseOrderFormat() {
    if (this.props.selectedRegistrationType.poFormatFlag === 'Y') {
      return (
        <div>
          {!this.state.registrationConfirmed ? (
            <this.purchaseOrderFormatOptions />
          ) : (
            ' '
          )}

          <this.selectedPurchaseOrderSummary />
        </div>
      )
    } else if (this.props.selectedRegistrationType.poFormatFlag === 'N') {
      return <React.Fragment></React.Fragment>
    }

    return <React.Fragment></React.Fragment>
  }

  confirmEditButton() {
    if (this.state.registrationConfirmed === true) {
      return <React.Fragment></React.Fragment>
    } else {
      return (
        <React.Fragment>
          <div className="hc-pt-dense hc-pb-dense">
            <Divider />
          </div>
          <div>
            <ul style={{ paddingLeft: '14px' }}>
              <li style={{ listStyleType: 'circle' }}>
                Click confirm if the above information is correct and no changes
                need to be made.
              </li>
              <li style={{ paddingTop: '6px', listStyleType: 'circle' }}>
                Click edit to go back and make changes.
              </li>
            </ul>
          </div>
          <div
            id="submitForm"
            className="hc-pt-dense hc-pb-expanded"
            style={{ marginTop: '10px' }}
          >
            <Button
              size="dense"
              type="secondary"
              onClick={() => {
                this.handleConfirmationContinue()
              }}
            >
              Confirm
            </Button>

            <Button
              className="hc-ml-dense"
              size="dense"
              type="secondary"
              onClick={() => {
                this.props.handleEdit()
              }}
            >
              Edit
            </Button>
          </div>
        </React.Fragment>
      )
    }
  }

  purchaseOrderFormatOptions() {
    return (
      <React.Fragment>
        <Heading size={5}>
          Choose the EDI 850/860 format that data will be sent as:
        </Heading>
        <div className="hc-pt-dense hc-pb-dense">
          <Divider />
        </div>
        <Input.Radio
          id="ediPoFormat"
          value={this.state.selectedPurchaseOrderFormat}
          onUpdate={(name, value) => {
            this.setState({
              selectedPurchaseOrderFormat: `${value}`,
            })
          }}
          options={[
            {
              value: 'Basic',
              label:
                'Basic format: data will be sent grouped by location, then by item, then by quantity.',
            },
            {
              value: 'SDQ',
              label:
                'SDQ format: data will be sent grouped by item, then by location, then by quantity.',
            },
          ]}
        />
      </React.Fragment>
    )
  }

  selectedPurchaseOrderVerbiage() {
    if (this.state.selectedPurchaseOrderFormat === 'Basic') {
      return ' format means that data will be sent grouped by location, then by item, then by quantity.'
    } else if (this.state.selectedPurchaseOrderFormat === 'SDQ') {
      return ' format means that data will be sent grouped by item, then by location, then by quantity.'
    }
    return ''
  }

  selectedPurchaseOrderSummary() {
    return (
      <React.Fragment>
        <p
          style={{ paddingLeft: '5px' }}
          className="hc-pt-dense hc-pb-expanded"
        >
          The EDI 850/860 format that data will be sent as:{' '}
          {this.state.selectedPurchaseOrderFormat}
          <br />
          <span className="ess-font-bold">
            {this.state.selectedPurchaseOrderFormat}{' '}
          </span>{' '}
          {this.selectedPurchaseOrderVerbiage()}
        </p>
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        <Grid.Item xs={12}>
          <this.PurchaseOrderFormat />
          <this.confirmEditButton />
        </Grid.Item>
      </React.Fragment>
    )
  }
}

export default PurchaseOrderFormatSelection
