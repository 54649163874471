import React from 'react'
import { Stepper } from '@enterprise-ui/canvas-ui-react'
import * as RegistrationConstants from './Constants.js'

class ActivityProgressBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      completedSteps: [],
    }

    this.isStepCompleted = this.isStepCompleted.bind(this)
  }

  isStepCompleted(indexOfCurrentStep, indexOfActiveStep) {
    if (indexOfCurrentStep < indexOfActiveStep) {
      return true
    }
    return false
  }

  render() {
    return (
      <Stepper
        activeStep={this.props.step}
        style={{ background: 'white', padding: '10px' }}
      >
        <Stepper.Item
          index={RegistrationConstants.REGISTRATION_STEP_1_ID}
          name={RegistrationConstants.REGISTRATION_STEP_1_ID}
          completed={this.isStepCompleted(
            RegistrationConstants.REGISTRATION_STEP_1_ID,
            this.props.step
          )}
        >
          {RegistrationConstants.REGISTRATION_STEP_1_LABEL}
        </Stepper.Item>
        <Stepper.Item
          index={RegistrationConstants.REGISTRATION_STEP_2_ID}
          name={RegistrationConstants.REGISTRATION_STEP_2_ID}
          completed={this.isStepCompleted(
            RegistrationConstants.REGISTRATION_STEP_2_ID,
            this.props.step
          )}
        >
          {RegistrationConstants.REGISTRATION_STEP_2_LABEL}
        </Stepper.Item>
        <Stepper.Item
          index={RegistrationConstants.REGISTRATION_STEP_3_ID}
          name={RegistrationConstants.REGISTRATION_STEP_3_ID}
          completed={this.isStepCompleted(
            RegistrationConstants.REGISTRATION_STEP_3_ID,
            this.props.step
          )}
        >
          {RegistrationConstants.REGISTRATION_STEP_3_LABEL}
        </Stepper.Item>
        <Stepper.Item
          index={RegistrationConstants.REGISTRATION_STEP_4_ID}
          name={RegistrationConstants.REGISTRATION_STEP_4_ID}
          completed={this.isStepCompleted(
            RegistrationConstants.REGISTRATION_STEP_4_ID,
            this.props.step
          )}
        >
          {RegistrationConstants.REGISTRATION_STEP_4_LABEL}
        </Stepper.Item>
        <Stepper.Item
          index={RegistrationConstants.REGISTRATION_STEP_5_ID}
          name={RegistrationConstants.REGISTRATION_STEP_5_ID}
          completed={this.isStepCompleted(
            RegistrationConstants.REGISTRATION_STEP_5_ID,
            this.props.step
          )}
        >
          {RegistrationConstants.REGISTRATION_STEP_5_LABEL}
        </Stepper.Item>
        <Stepper.Item
          index={RegistrationConstants.REGISTRATION_STEP_6_ID}
          name={RegistrationConstants.REGISTRATION_STEP_6_ID}
          completed={this.isStepCompleted(
            RegistrationConstants.REGISTRATION_STEP_6_ID,
            this.props.step
          )}
        >
          {RegistrationConstants.REGISTRATION_STEP_6_LABEL}
        </Stepper.Item>
        <Stepper.Item
          index={RegistrationConstants.REGISTRATION_STEP_7_ID}
          name={RegistrationConstants.REGISTRATION_STEP_7_ID}
        >
          {RegistrationConstants.REGISTRATION_STEP_7_LABEL}
        </Stepper.Item>
      </Stepper>
    )
  }
}

export default ActivityProgressBar
