import React from 'react'
import axios from 'axios'
import { Form, Anchor, Tooltip } from '@enterprise-ui/canvas-ui-react'

class ServiceProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedServiceProvider: '',
      serviceProviders: [],
      lorenDataResponse: null,
    }
    this.handleServiceProviderChange =
      this.handleServiceProviderChange.bind(this)
  }

  /* Loads Service Provider Data from api when the component renders*/
  componentDidMount() {
    const operatingCompany = this.props.operatingCompany
    const SERVICE_PROVIDER_ENDPOINT =
      this.props.env.ess.url +
      '/api/v1/service-providers?operatingCompany=' +
      operatingCompany
    this.fetchServiceProviderEndpoint(SERVICE_PROVIDER_ENDPOINT)
  }

  fetchServiceProviderEndpoint(SERVICE_PROVIDER_ENDPOINT) {
    axios
      .get(SERVICE_PROVIDER_ENDPOINT)
      // .then((response) => {
      //   if (response.ok) {
      //     return response.json()
      //   } else {
      //     this.props.handleException(
      //       'Unexpected error occurred while calling endpoint -->' +
      //         SERVICE_PROVIDER_ENDPOINT
      //     )
      //   }
      // })
      .then((response) => {
        var data = response.data
        this.setState({
          serviceProviders: data,
        })
      })
      .catch((error) =>
        this.props.handleException(
          'Unexpected error occurred while calling endpoint -->' +
            SERVICE_PROVIDER_ENDPOINT +
            ' Error: ' +
            error
        )
      )
  }

  handleServiceProviderChange(serviceProvider) {
    this.setState({ selectedServiceProvider: serviceProvider })
    this.props.onHandle(serviceProvider)
  }

  render() {
    const serviceProviderList = []
    serviceProviderList.push(' ')
    this.state.serviceProviders.map((serviceProvider) => {
      var vendorObj = {
        label: serviceProvider.serviceProviderName,
        value: serviceProvider,
      }
      serviceProviderList.push(vendorObj)
      return serviceProviderList
    })
    return (
      <div>
        <Form.Field
          type="select"
          id="serviceProviderlist"
          label="Service Provider"
          hintText="Select the Service Provider"
          errorText="Please select a Service Provider"
          value={this.state.selectedServiceProvider}
          onUpdate={(id, value) => this.handleServiceProviderChange(value)}
          required
          disabled={this.props.disabled}
          options={serviceProviderList}
          rightContent={
            <Tooltip
              className="C-toolpit-custom-width"
              wrap
              location="top-left"
              content="A Service Provider is a company that will be transmitting data on your behalf. If you need help selecting a provider, please search ‘EDI Service Provider list’ on POL.
If your company has a service provider and it’s listed, please select the appropriate name to continue.
If your company has a service provider but it’s not listed, please select Loren Data to continue."
            >
              <Anchor>What is this?</Anchor>
            </Tooltip>
          }
        />
      </div>
    )
  }
}

export default ServiceProvider
