import React from 'react'
import {
  Button,
  GridContainer,
  Grid,
  Form,
  InputCheckbox,
} from '@enterprise-ui/canvas-ui-react'

class EmailRecipientsInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      businessPartnerContact: {
        firstName: '',
        lastName: '',
        emailAddress: [''],
        phone: [''],
      },
    }
    this.handleEmailRecipientInputCheckbox =
      this.handleEmailRecipientInputCheckbox.bind(this)
  }

  render() {
    return (
      <React.Fragment>
        <InputCheckbox
          id="EmailRecipientsInputCheckbox"
          label="Click the checkbox to enter an email recipient"
          onChange={(event) => {
            this.handleEmailRecipientInputCheckbox(event)
          }}
        />
        <br />
        {this.props.showEmailRecipientInputFields ? (
          <GridContainer id="EmailRecipientInput">
            <Grid.Item xs={3}>
              <Form.Field
                id="firstName"
                label="First Name"
                type="text"
                required
                hintText="Enter the First Name"
                value={this.state.businessPartnerContact.firstName}
                error={this.props.isFirstNameInvalid}
                errorText="Please enter a value."
                disabled={this.props.showEmailRecipientEditButton}
                onChange={(e) => {
                  this.setState({
                    businessPartnerContact: {
                      ...this.state.businessPartnerContact,
                      firstName: e.target.value,
                    },
                  })
                  const bizContact = {
                    ...this.state.businessPartnerContact,
                    firstName: e.target.value,
                  }
                  this.props.onEmailRecipientInputChange(bizContact)
                }}
              />
            </Grid.Item>
            <Grid.Item xs={3}>
              <Form.Field
                id="lastName"
                label="Last Name"
                type="text"
                required
                hintText="Enter the Last Name"
                value={this.state.businessPartnerContact.lastName}
                error={this.props.isLastNameInvalid}
                errorText="Please enter a value."
                disabled={this.props.showEmailRecipientEditButton}
                onChange={(e) => {
                  this.setState({
                    businessPartnerContact: {
                      ...this.state.businessPartnerContact,
                      lastName: e.target.value,
                    },
                  })
                  const bizContact = {
                    ...this.state.businessPartnerContact,
                    lastName: e.target.value,
                  }
                  this.props.onEmailRecipientInputChange(bizContact)
                }}
              />
            </Grid.Item>
            <Grid.Item xs={3}>
              <Form.Field
                id="emailAddress"
                label="Email Address"
                type="email"
                required
                hintText="Enter the Email Address"
                value={this.state.businessPartnerContact.emailAddress}
                error={this.props.isEmailAddressInvalid}
                errorText="Please enter a valid email address."
                disabled={this.props.showEmailRecipientEditButton}
                onChange={(e) => {
                  this.setState({
                    businessPartnerContact: {
                      ...this.state.businessPartnerContact,
                      emailAddress: [e.target.value],
                    },
                  })
                  const bizContact = {
                    ...this.state.businessPartnerContact,
                    emailAddress: [e.target.value],
                  }
                  this.props.onEmailRecipientInputChange(bizContact)
                }}
              />
            </Grid.Item>
            <Grid.Item xs={3}>
              <Form.Field
                id="phoneNumber"
                label="Phone Number"
                type="tel"
                required
                hintText="Enter the Phone Number"
                value={this.state.businessPartnerContact.phone}
                error={this.props.isPhoneNumberInvalid}
                errorText="Please enter a value."
                disabled={this.props.showEmailRecipientEditButton}
                onChange={(e) => {
                  this.setState({
                    businessPartnerContact: {
                      ...this.state.businessPartnerContact,
                      phone: [e.target.value],
                    },
                  })
                  const bizContact = {
                    ...this.state.businessPartnerContact,
                    phone: [e.target.value],
                  }
                  this.props.onEmailRecipientInputChange(bizContact)
                }}
              />
            </Grid.Item>
            {this.props.showEmailRecipientInputFields &&
            this.props.showEmailRecipientEditButton &&
            this.props.confimrationEmailSent ? (
              <Grid.Item>
                <Button
                  spacing="dense"
                  type="secondary"
                  onClick={this.editEmailRecipient.bind(this)}
                >
                  Edit
                </Button>
              </Grid.Item>
            ) : (
              ' '
            )}
          </GridContainer>
        ) : (
          ' '
        )}
      </React.Fragment>
    )
  }

  handleEmailRecipientInputCheckbox(event) {
    const toggleShowEmailRecipient = !this.props.showEmailRecipientInputFields
    this.props.onEmailRecipientInputEnabledChange(toggleShowEmailRecipient)
  }

  editEmailRecipient() {
    this.props.editEmailRecipient()
  }
}

export default EmailRecipientsInput
