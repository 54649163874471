import React from 'react'
import axios from 'axios'
import { Button, Anchor } from '@enterprise-ui/canvas-ui-react'
import Logger from '../../../global/util/Logger'
import {
  LOGGER_LEVEL_ERROR,
  LOGGER_LEVEL_INFO,
} from '../../registration/common/Constants'
import { trackPromise } from 'react-promise-tracker'
import BusinessPartnerContact from '../../registration/BusinessPartnerContacts'
import EmailRecipientInput from './EmailRecipientInput'

class ResendRegistrationConfirmationEmail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showBusinessPartnerContacts: false,
      businessPartnerContact: {
        firstName: '',
        lastName: '',
        emailAddress: [''],
        phone: [''],
      },
      showEmailRecipientInputFields: false,
      showEmailRecipientEditButton: false,
      showResendButton: false,
      businessContactsSelected: false,
      isFirstNameInvalid: null,
      isLastNameInvalid: null,
      isEmailAddressInvalid: null,
      isPhoneNumberInvalid: null,
    }
    this.resendRegistrationConfirmationEmails =
      this.resendRegistrationConfirmationEmails.bind(this)
    this.onContactTypeChange = this.onContactTypeChange.bind(this)
    this.onEmailRecipientInputEnabledChange =
      this.onEmailRecipientInputEnabledChange.bind(this)
    this.onEmailRecipientInputChange =
      this.onEmailRecipientInputChange.bind(this)
    this.editEmailRecipient = this.editEmailRecipient.bind(this)
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        {this.state.showBusinessPartnerContacts ? (
          <div>
            <BusinessPartnerContact
              env={this.props.env}
              gmsVendorNumber={this.props.registration.gmsVendorNumber}
              onContactTypeChange={this.onContactTypeChange}
              onEdit={() => {}}
            />
            <br />
            <EmailRecipientInput
              env={this.props.env}
              onEmailRecipientInputEnabledChange={
                this.onEmailRecipientInputEnabledChange
              }
              onEmailRecipientInputChange={this.onEmailRecipientInputChange}
              showEmailRecipientEditButton={
                this.state.showEmailRecipientEditButton
              }
              editEmailRecipient={this.editEmailRecipient}
              isFirstNameInvalid={this.state.isFirstNameInvalid}
              isLastNameInvalid={this.state.isLastNameInvalid}
              isEmailAddressInvalid={this.state.isEmailAddressInvalid}
              isPhoneNumberInvalid={this.state.isPhoneNumberInvalid}
              confimrationEmailSent={this.state.confimrationEmailSent}
              showEmailRecipientInputFields={
                this.state.showEmailRecipientInputFields
              }
            />
            <br />
            {this.state.businessContactsSelected ||
            this.state.showResendButton ? (
              <div>
                <Button
                  spacing="dense"
                  type="secondary"
                  onClick={this.resendRegistrationConfirmationEmails}
                >
                  Resend Confirmation Email
                </Button>
                <br />
                <br />
              </div>
            ) : (
              ' '
            )}
          </div>
        ) : (
          <div>
            <span>
              <Anchor
                onClick={() => {
                  this.setState({
                    showBusinessPartnerContacts: true,
                  })
                }}
              >
                Click Here
              </Anchor>{' '}
              to resend confirmation email
            </span>
          </div>
        )}
        <span
          style={{ fontWeight: 'bold' }}
          className={
            this.state.confimrationEmailSent ? 'hc-clr-success' : 'hc-clr-error'
          }
        >
          {this.state.confimrationEmailSent === true
            ? 'Successfully sent confirmation email.'
            : ''}
          {this.state.confimrationEmailSent === false
            ? 'There was an issue resending the confirmation email'
            : ''}
        </span>
      </div>
    )
  }

  editEmailRecipient() {
    this.setState({
      showEmailRecipientEditButton: false,
      confimrationEmailSent: null,
    })
  }

  isValidEmailAddressFormat(emailId) {
    const expression = /\S+@\S+/
    return expression.test(String(emailId).toLowerCase())
  }

  isEmpty = (str) => {
    return !str.trim().length
  }

  validateInputFields() {
    let isValid = true
    if (this.isEmpty(this.state.businessPartnerContact.firstName)) {
      isValid = false
      this.setState({ isFirstNameInvalid: true })
    } else {
      this.setState({ isFirstNameInvalid: false })
    }

    if (this.isEmpty(this.state.businessPartnerContact.lastName)) {
      isValid = false
      this.setState({ isLastNameInvalid: true })
    } else {
      this.setState({ isLastNameInvalid: false })
    }

    if (
      !this.isValidEmailAddressFormat(
        this.state.businessPartnerContact.emailAddress[0]
      )
    ) {
      isValid = false
      this.setState({ isEmailAddressInvalid: true })
    } else {
      this.setState({ isEmailAddressInvalid: false })
    }

    if (this.isEmpty(this.state.businessPartnerContact.phone[0])) {
      isValid = false
      this.setState({ isPhoneNumberInvalid: true })
    } else {
      this.setState({ isPhoneNumberInvalid: false })
    }
    return isValid
  }

  onContactTypeChange(businessPartnerContacts) {
    if (businessPartnerContacts) {
      this.setState({
        selectedBusinessPartnerContact: businessPartnerContacts,
      })
      if (businessPartnerContacts.length === 0) {
        this.setState({
          businessContactsSelected: false,
        })
      } else {
        this.setState({
          businessContactsSelected: true,
        })
      }
    }
  }

  onEmailRecipientInputEnabledChange(checkedStatus) {
    this.setState({
      showResendButton: checkedStatus,
      showEmailRecipientInputFields: checkedStatus,
    })
  }

  onEmailRecipientInputChange(businessPartnerContact) {
    this.setState({
      businessPartnerContact: businessPartnerContact,
    })
  }

  resendRegistrationConfirmationEmails() {
    if (
      (this.state.businessContactsSelected &&
        !this.state.showEmailRecipientInputFields) ||
      this.validateInputFields()
    ) {
      var userRegistration = {}
      userRegistration.serviceProvider = this.props.registration.serviceProvider
      userRegistration.registrationType =
        this.props.registration.registrationType
      userRegistration.ediId = this.props.registration.ediPartnerId
      userRegistration.qualifier = this.props.registration.ediPartnerQualifier
      userRegistration.operatingCompany =
        this.props.registration.operatingCompany
      userRegistration.registrationGenId =
        this.props.registration.registrationGenId
      userRegistration.registrationTypeDocuments =
        this.props.registrationTypesDoc
      userRegistration.companyId = this.props.registration.companyId
      userRegistration.vendorName = this.props.registration.partnerName
      userRegistration.gmsVendorNumber = this.props.registration.gmsVendorNumber
      userRegistration.userLanId = this.props.userInfo.lanId
      console.log(
        'this.props.registration: ' + JSON.stringify(this.props.registration)
      )
      userRegistration.purchaseOrderFormat = this.props.registration.poFormat

      if (this.props.registration.transportationFlag === 'Y') {
        console.log('Vendor is a transportation Vendor')
        userRegistration.isTransportationVendor = true
        userRegistration.scacCode = this.props.registration.companyId
      }
      userRegistration.registrationTypeDocuments =
        this.props.registrationTypesDoc

      if (this.state.businessContactsSelected) {
        userRegistration.businessPartnerContact = [
          ...this.state.selectedBusinessPartnerContact,
        ]
        if (this.state.showEmailRecipientInputFields) {
          userRegistration.businessPartnerContact.push(
            this.state.businessPartnerContact
          )
        }
      } else {
        userRegistration.businessPartnerContact = []
        userRegistration.businessPartnerContact.push(
          this.state.businessPartnerContact
        )
      }

      const RESEND_EMAIL_ENDPOINT =
        '/api/v1/resend-registration-approval-emails'

      const requestOptions = {
        method: 'post',
        url: this.props.env.ess.url + RESEND_EMAIL_ENDPOINT,
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(userRegistration),
      }
      Logger(
        'Registration Maintenance - Resend registartion approval email',
        LOGGER_LEVEL_INFO,
        this.props.env.ess.url + RESEND_EMAIL_ENDPOINT
      )
      trackPromise(
        axios(requestOptions)
          .then((response) => {
            var data = response.data
            console.log(
              'Registration Maintenance - Resend Approval Email: ' + data
            )
            this.setState({
              confimrationEmailSent: true,
              showEmailRecipientEditButton: true,
            })
            Logger(
              'Resend Email action',
              LOGGER_LEVEL_INFO,
              this.props.env.ess.url + RESEND_EMAIL_ENDPOINT
            )
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
              confimrationEmailSent: false,
            })
            Logger(
              'Error while performing resend approval email: error' + error,
              LOGGER_LEVEL_ERROR,
              this.props.env.ess.url + RESEND_EMAIL_ENDPOINT
            )
          })
      )
    }
  }
}

export default ResendRegistrationConfirmationEmail
