import AccessControl from './global/security/AccessControl'
import { Route, Routes } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import ErrorBoundary from './global/util/ErrorBoundary'
import RegistrationForm from './components/registration/Form'
import RegistrationMaintenance from './components/registration-maintenance/RegistrationStatus'
import RegistrationDetails from './components/registration-maintenance/details/RegistrationDetails'

const defaultHistory = createBrowserHistory()

const Home = (props, { history = defaultHistory }) => {
  return (
    <ErrorBoundary>
      <Routes history={history}>
        <Route path="" element={<RegistrationForm {...props} />} />
        <Route
          path="/status"
          element={
            <AccessControl
              role={[props.env.helpDeskAdminRole, props.env.supportAdminRole]}
              env={props.env}
            >
              <RegistrationMaintenance {...props} />
            </AccessControl>
          }
        />
        <Route
          path="/details"
          element={
            <AccessControl
              role={[props.env.helpDeskAdminRole, props.env.supportAdminRole]}
              env={props.env}
            >
              <RegistrationDetails {...props} />
            </AccessControl>
          }
        />
      </Routes>
    </ErrorBoundary>
  )
}
export default Home
