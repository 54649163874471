import React from 'react'
import axios from 'axios'
import * as GlobalConstants from './common/Constants'
import { Heading, Divider, Grid } from '@enterprise-ui/canvas-ui-react'
import PartnerProfileDetails from './PartnerProfileDetails'

class RegistrationSummary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      registrationTypesDoc: [],
    }
    this.getRegistrationTypeDocuments =
      this.getRegistrationTypeDocuments.bind(this)
    this.SegmentInformation = this.SegmentInformation.bind(this)
  }

  componentDidMount() {
    console.log('props passed: ' + JSON.stringify(this.props))
    console.log('props registrationTypeId: ' + this.props.registrationTypeId)
    console.log('props operatingCompany: ' + this.props.operatingCompany)

    this.getRegistrationTypeDocuments(
      this.props.registrationTypeId,
      this.props.operatingCompany
    )
  }

  render() {
    return (
      <React.Fragment>
        <Grid.Item xs={6}>
          <Heading size={5} className="hc-pt-dense hc-pb-dense">
            Vendor Selected:
          </Heading>
          <Divider />
          <p id="ecom_vendor_number" className="hc-pt-dense hc-pb-expanded">
            Vendor Name: {this.props.vendorName}
            <br />
            Vendor Number: {this.props.vendorNumber}
            <br />
            {this.props.isTransportation ? (
              <span>
                SCAC: {this.props.scacCode} <br />
              </span>
            ) : (
              ' '
            )}
            {this.props.operatingCompany ===
            GlobalConstants.OPERATING_COMPANY_DVS ? (
              <b>Ecom Vendor Number: {this.props.ecomVendorNumber}</b>
            ) : (
              ''
            )}
          </p>
          <Heading size={5} className="hc-pt-dense hc-pb-dense">
            EDI Partner Info:
          </Heading>
          <Divider />
          <p className="hc-pt-dense hc-pb-expanded">
            EDI ID: <b>{this.props.ediId}</b>
            <br />
            Qualifier: <b>{this.props.qualifier}</b>
          </p>
          <Heading size={5} className="hc-pt-dense hc-pb-dense">
            Service Provider Selected:
          </Heading>
          <Divider />
          <p className="hc-pt-dense hc-pb-expanded">
            {this.props.selectedServiceProvider?.serviceProviderName}
          </p>
          <Heading size={5} className="hc-pt-dense hc-pb-dense">
            Registration Type Selected:
          </Heading>
          <Divider />
          <p className="hc-pt-dense">
            {this.props.selectedRegistrationType?.registrationTypeName}
          </p>
        </Grid.Item>
        <Grid.Item xs={8}>
          <PartnerProfileDetails
            registrationTypesDoc={this.state.registrationTypesDoc}
          />
        </Grid.Item>
        <Grid.Item xs={8}>
          <Heading size={5} className="hc-pt-dense hc-pb-dense">
            Target’s Interchange Details:
          </Heading>
          <Divider />
          <p className="hc-pt-dense hc-pb-expanded">
            EDI ID: {this.props.selectedRegistrationType?.targetCorpPartnerId}
            <br />
            Qualifier:{' '}
            {this.props.selectedRegistrationType?.targetCorpPartnerQualifier}
            <br />
            Interchange Control Version:{' '}
            {this.props.selectedRegistrationType?.interchangeVersion}
            <br />
          </p>
          <Divider />
          {this.props.showSegmentInfo ? <this.SegmentInformation /> : ''}
        </Grid.Item>
      </React.Fragment>
    )
  }

  SegmentInformation() {
    return (
      <div>
        <Heading size={5} className="hc-pt-dense hc-pb-dense">
          Delimiters to be used for sending and receiving EDI data:
        </Heading>
        <p className="hc-pt-dense hc-pb-dense">
          Segment Terminator:{' '}
          {this.props.selectedRegistrationType?.segmentTerminator}
          <br />
          Element Separator:{' '}
          {this.props.selectedRegistrationType?.elementSeparator}
          <br />
          Component Element Separator:{' '}
          {this.props.selectedRegistrationType?.subelementSeparator}
          <br />
        </p>
      </div>
    )
  }

  getRegistrationTypeDocuments(registrationTypeId, operatingCompany) {
    console.log(
      'businessPartnerRoleId: ' +
        registrationTypeId +
        ' operatingCompany: ' +
        operatingCompany
    )
    const REGISTRATION_TYPE_DOC_ENDPOINT =
      this.props.env.ess.url +
      '/api/v1/registration-type-doc?operatingCompany=' +
      operatingCompany +
      '&registrationTypeId=' +
      registrationTypeId
    axios
      .get(REGISTRATION_TYPE_DOC_ENDPOINT)
      .then((response) => {
        var data = response.data
        this.setState({
          registrationTypesDoc: data,
        })
        if (this.props.returnRegistrationDocuments) {
          this.props.getRegistrationTypeDocuments(data)
        }
      })
      .catch((error) => {
        console.log(
          'Unexpected Error occurred while trying to access the Registration Type Endpoint' +
            REGISTRATION_TYPE_DOC_ENDPOINT +
            ' Error: ' +
            error
        )
      })
  }
}

export default RegistrationSummary
