import React from 'react'
import axios from 'axios'
import {
  Form,
  Anchor,
  Tooltip,
  Grid,
  Button,
} from '@enterprise-ui/canvas-ui-react'
import AnchorEdit from './common/AnchorEdit'
import { RegistrationError } from './common/RegistrationError'
import * as ErrorConstants from './common/ErrorConstants'
import AccessControl from '../../global/security/AccessControl'

class VendorSelection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vendors: [],
      selectedVendor: '',
      isVendorSelected: false,
      inEditMode: false,
      isEditing: false,
      gmsVendorNumber: '',
      vendorName: '',
      editMode: true,
      isVendorDetailsExtracted: false,
      errorOnLoadingVendorDetails: false,
      isTransportationVendor: false,
      isScacCodeNull: false,
    }
    this.onVendorChange = this.onVendorChange.bind(this)
    this.editVendorSelection = this.editVendorSelection.bind(this)
    this.ContinueEditButton = this.ContinueEditButton.bind(this)
    this.handleContinue = this.handleContinue.bind(this)
    this.enterVendorNumber = this.enterVendorNumber.bind(this)
    this.displayVendorName = this.displayVendorName.bind(this)
    this.internalUserVendorInformation =
      this.internalUserVendorInformation.bind(this)
  }

  /* Loads Vendor Data from Service */
  componentDidMount() {
    console.log('Vendor panel userRoles: ' + this.props.userRoles)
    const USERID = this.props.userid
    let config = {
      headers: {
        'Id-Token': window.localStorage.id_token,
      },
    }
    const VENDOR_DATA_ENDPOINT =
      this.props.env.ess.url + '/api/v1/vendors?user_id=' + USERID
    this.setState({ serviceError: false })
    if (
      this.props.userRoles &&
      this.props.userRoles.includes(this.props.env.externalUserRole)
    ) {
      axios
        .get(VENDOR_DATA_ENDPOINT, config)
        .then((response) => {
          var data = response.data
          this.setState({
            vendors: data,
          })
        })
        .catch((error) => {
          this.props.handleException(
            'Unexpected error occurred while calling endpoint --> /api/v1/vendors?user_id=' +
              USERID +
              ' Error: ' +
              error
          )
        })
    }
  }

  getVendorDetails(gmsVendorNumber) {
    this.setState({ isScacCodeNull: false })
    this.setState({ isTransportationVendor: false })
    this.setState({ errorOnLoadingVendorDetails: false })
    console.log('yooo')
    let config = {
      headers: {
        'Id-Token': window.localStorage.id_token,
      },
    }
    axios
      .get(
        this.props.env.ess.url +
          '/api/v1/vendor-details?gmsVendorNumber=' +
          gmsVendorNumber,
        config
      )
      .then((response) => {
        this.props.onLoadVendorDetails(response.data)
        if (response.data.isTransportationVendor) {
          this.setState({ isTransportationVendor: true })
          if (
            response.data.vendor.vendor_gvi.transport_vendor.scac_code === null
          ) {
            this.setState({ isScacCodeNull: true })
          }
        }
        if (
          !this.state.isTransportationVendor ||
          (this.state.isTransportationVendor && !this.state.isScacCodeNull)
        ) {
          this.setState({
            isVendorDetailsExtracted: true,
            vendorName: response.data.vendor.vendor_gvi.vendor_name,
            editMode: false,
          })
        }
      })
      .catch((error) => {
        this.setState({ errorOnLoadingVendorDetails: true, editMode: true })
      })
  }

  onVendorChange(selectedVendor) {
    this.setState({
      selectedVendor: selectedVendor,
      isVendorSelected: true,
    })
    this.getVendorDetails(selectedVendor.gmsVendorNumber)
    this.props.onVendorChange(selectedVendor)
  }

  onVendorChangeInternalUser(gmsVendorNumber) {
    this.setState({
      gmsVendorNumber: gmsVendorNumber,
      serviceError: false,
    })
    this.getVendorDetails(gmsVendorNumber)
  }

  validateVendorId(gmsVendorNumber) {
    var regex = new RegExp('^[a-zA-Z0-9]+$')
    if (!regex.test(gmsVendorNumber)) {
      if (gmsVendorNumber === '') {
        return false
      } else {
        return true
      }
    }
  }

  handleContinue() {
    this.onVendorChangeInternalUser(this.state.gmsVendorNumber)
  }

  ContinueEditButton() {
    if (this.state.editMode) {
      return (
        <Button onClick={this.handleContinue} type="secondary">
          CONTINUE
        </Button>
      )
    }
    return (
      <AnchorEdit
        handleEdit={this.editVendorSelection}
        editMode={!this.state.editMode}
        isEditing={this.state.isEditing}
      />
    )
  }

  enterVendorNumber() {
    return (
      <React.Fragment>
        <Grid.Item xs={4}>
          <Form.Field
            id="VendorEntered"
            label="Vendor ID"
            hintText="Enter the Vendor Number"
            error={
              this.validateVendorId(this.state.gmsVendorNumber) ||
              this.state.errorOnLoadingVendorDetails
            }
            errorText={
              this.validateVendorId(this.state.gmsVendorNumber)
                ? 'Cannot contain special characters or spaces.  Please enter a valid Vendor Number.'
                : this.state.errorOnLoadingVendorDetails
                ? 'Invalid vendor number. Please correct and enter again.'
                : ''
            }
            value={this.state.gmsVendorNumber}
            required
            disabled={!this.state.editMode}
            rightContent={
              <Tooltip
                location="top-left"
                content="Vendor ID that is being registered for EDI."
              >
                <Anchor>What is this?</Anchor>
              </Tooltip>
            }
            onChange={(e) => {
              this.setState({
                errorOnLoadingVendorDetails: false,
                gmsVendorNumber: e.target.value.toUpperCase(),
              })
            }}
          />
        </Grid.Item>
      </React.Fragment>
    )
  }

  displayVendorName() {
    return (
      <div>
        <React.Fragment>
          <Grid.Item xs={4}>
            <Form.Field
              id="VendorEntered"
              label="Vendor ID"
              hintText="Enter the Vendor Number"
              value={this.state.gmsVendorNumber + ' - ' + this.state.vendorName}
              required
              disabled
              rightContent={
                <Tooltip
                  location="top-left"
                  content="Vendor ID that is being registered for EDI."
                >
                  <Anchor>What is this?</Anchor>
                </Tooltip>
              }
            />
          </Grid.Item>
        </React.Fragment>
      </div>
    )
  }

  internalUserVendorInformation() {
    if (this.state.editMode) {
      return <this.enterVendorNumber />
    } else if (this.state.isVendorDetailsExtracted && !this.state.editMode) {
      return <this.displayVendorName />
    }
    return <React.Fragment></React.Fragment>
  }

  editVendorSelection() {
    this.setState({
      isTransportationVendor: false,
      isScacCodeNull: false,
      isVendorSelected: false,
      inEditMode: true,
      isEditing: true,
      editMode: true,
    })
    this.props.onEdit()
  }

  render() {
    const vendorList = []
    if (
      this.props.userRoles &&
      this.props.userRoles.includes(this.props.env.externalUserRole)
    ) {
      vendorList.push(' ')
      if (this.state.vendors) {
        this.state.vendors.map((vendor) => {
          var vendorObj = {
            label: vendor.gmsVendorNumber + ' - ' + vendor.vendorName,
            value: vendor,
          }
          vendorList.push(vendorObj)
          return vendorList
        })
      }
    }
    return (
      <div style={{ width: '100%' }}>
        <AccessControl
          role={[this.props.env.externalUserRole]}
          env={this.props.env}
        >
          <Grid.Item xs={4}>
            <Form.Field
              type="select"
              id="Vendorlist"
              label="Vendor ID"
              hintText="Select the Vendor"
              error={this.state.isScacCodeNull}
              errorText={
                this.state.isScacCodeNull
                  ? 'SCAC code is empty for the selected Transportation Vendor. Please reach out to edi.helpdesk@target.com for further assistance.'
                  : ''
              }
              value={this.state.selectedVendor}
              onUpdate={(id, value) => this.onVendorChange(value)}
              required
              options={vendorList}
              disabled={
                this.state.isVendorSelected && !this.state.isScacCodeNull
              }
              rightContent={
                <Tooltip
                  location="top-left"
                  content="Vendor ID that is being registered for EDI."
                >
                  <Anchor>What is this?</Anchor>
                </Tooltip>
              }
            />
          </Grid.Item>
          <Grid.Item xs={4}>
            <AnchorEdit
              handleEdit={this.editVendorSelection}
              handleContinue={() => {
                this.setState({ isVendorSelected: true })
                this.onVendorChange(this.state.selectedVendor)
              }}
              editMode={
                this.state.isVendorSelected && !this.state.isScacCodeNull
              }
              isEditing={this.state.isEditing}
            />
          </Grid.Item>
        </AccessControl>

        <AccessControl
          role={[
            this.props.env.helpDeskAdminRole,
            this.props.env.supportAdminRole,
          ]}
          env={this.props.env}
        >
          <this.internalUserVendorInformation />
          <br />
          <this.ContinueEditButton />
        </AccessControl>

        <Grid.Item xs={12}>
          <RegistrationError
            inError={this.state.serviceError}
            message={ErrorConstants.VMM_ERROR_MESSAGE}
          />
        </Grid.Item>
      </div>
    )
  }
}
export default VendorSelection
