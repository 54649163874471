import React from 'react'
import axios from 'axios'
import ServiceProvider from './ServiceProviderSelectionContent'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Heading, Grid } from '@enterprise-ui/canvas-ui-react'
import HubbubPortalPanel from './HubbubPortalPanel'
import { trackPromise } from 'react-promise-tracker'
import AnchorEdit from './common/AnchorEdit'
import { RegistrationError } from './common/RegistrationError'
import * as ErrorConstants from './common/ErrorConstants'

class ServiceProviderSelectionPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shouldRegisterInHubbubPortal: false,
      lorenDataResponse: null,
      selectedServiceProvider: null,
      isServiceProviderSelected: false,
    }
    this.handleLorenDataCallback = this.handleLorenDataResponse.bind(this)
    this.HubbubPortal = this.HubbubPortal.bind(this)
    this.handleServiceProviderChange =
      this.handleServiceProviderChange.bind(this)
    this.editServiceProvierSelection =
      this.editServiceProvierSelection.bind(this)
  }

  handleServiceProviderChange(serviceProvider) {
    this.props.resetServiceProvider()
    this.setState({
      isServiceProviderSelected: true,
      isEditing: false,
      lorenDataResponse: null,
      shouldRegisterInHubbubPortal: false,
      selectedServiceProvider: serviceProvider,
    })
    if (this.shouldCallLorenData(serviceProvider)) {
      console.log('Invoking Loren Data endoint')
      this.callLorenDataEndpoint(serviceProvider)
    } else {
      console.log('Loren Data endpoint will not be called')
      this.props.handleServiceProviderChange(serviceProvider)
    }
  }

  callLorenDataEndpoint() {
    const ediId = this.props.ediId
    const qualifier = this.props.qualifier
    const vendorName = this.props.vendorName
    const LOREN_DATA_ENDPOINT =
      this.props.env.ess.url +
      '/api/v1/validate-ld?ediId=' +
      ediId +
      '&qualifier=' +
      qualifier +
      '&vendorName=' +
      vendorName
    this.setState({
      serviceError: false,
      isInvokingHubbub: true,
    })
    trackPromise(
      axios
        .get(LOREN_DATA_ENDPOINT)
        .then((response) => {
          var data = response.data
          console.log('response from service:' + JSON.stringify(data))
          this.setState({
            lorenDataResponse: data,
            isInvokingHubbub: false,
          })
          this.handleLorenDataResponse(data)
        })
        .catch((error) => {
          console.log(
            'Unexpected Error occurred while trying to access the Service Provider Endpoint --> /api/v1/validate-ld?ediId=' +
              ediId +
              '&qualifier=' +
              qualifier,
            '&vendorName= ' + vendorName + ' Error: ' + error
          )
          this.setState({
            serviceError: true,
            isInvokingHubbub: false,
          })
        })
    )
  }

  shouldCallLorenData(serviceProvider) {
    return serviceProvider && serviceProvider.autoApprovedFlag === 'L'
  }

  handleLorenDataResponse(lorenDataResponse) {
    if (lorenDataResponse != null) {
      this.setState({
        shouldRegisterInHubbubPortal: lorenDataResponse.success,
      })
    }
    if (lorenDataResponse == null || !lorenDataResponse.success) {
      console.log('response from Loren data is false: moving to next step')
      this.props.handleServiceProviderChange(this.state.selectedServiceProvider)
    }
    console.log(
      'Hubbub portal information should be displayed: ' +
        this.state.shouldRegisterInHubbubPortal
    )
  }

  editServiceProvierSelection() {
    this.setState({
      isServiceProviderSelected: false,
      isEditing: true,
    })
    this.props.onEdit()
  }

  render() {
    return (
      <React.Fragment>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Heading size={5}>Service Provider Selection</Heading>
          </AccordionSummary>
          <AccordionDetails>
            <Grid.Container>
              <Grid.Item xs={4}>
                <ServiceProvider
                  onHandle={this.handleServiceProviderChange}
                  disabled={
                    this.state.isServiceProviderSelected &&
                    this.state.selectedServiceProvider !== undefined
                  }
                  {...this.props}
                />
                <br />
                {this.state.selectedServiceProvider !== undefined && (
                  <AnchorEdit
                    editMode={this.state.isServiceProviderSelected}
                    handleEdit={this.editServiceProvierSelection}
                    isEditing={this.state.isEditing}
                    handleContinue={() => {
                      this.handleServiceProviderChange(
                        this.state.selectedServiceProvider
                      )
                    }}
                  />
                )}
                {this.state.isInvokingHubbub ? (
                  <div className="hc-pt-dense hc-pb-expanded">
                    Loading information from Hubbub portal...
                  </div>
                ) : (
                  ' '
                )}
              </Grid.Item>
              <Grid.Item xs={12}>
                <RegistrationError
                  inError={this.state.serviceError}
                  message={ErrorConstants.HUBBUB_UNAVAILABLE}
                />
                <this.HubbubPortal />
              </Grid.Item>
            </Grid.Container>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    )
  }

  HubbubPortal() {
    if (this.state.shouldRegisterInHubbubPortal) {
      return (
        <HubbubPortalPanel
          env={this.props.env}
          email={this.props.email}
          hubbublink={
            this.state.lorenDataResponse != null
              ? this.state.lorenDataResponse.hubbubPortalUrl
              : ''
          }
        />
      )
    } else {
      return <React.Fragment></React.Fragment>
    }
  }
}

export default ServiceProviderSelectionPanel
