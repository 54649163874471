export const ValidateInput = (input) => {
  var regex = new RegExp('^[a-zA-Z0-9]+$')
  if (!regex.test(input)) {
    if (input.trim() === '') {
      return false
    } else {
      return true
    }
  }
}
