import React from 'react'
import { GridItem } from '@enterprise-ui/canvas-ui-react'
import * as moment from 'moment'
import { ArrowUpward, ArrowDownward } from '@material-ui/icons'
import MaterialTable from 'material-table'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronRight from '@material-ui/icons/ChevronRight'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import '../../../global/css/MaterialTable.css'

export default function RegistrationStatusResultsTable(props) {
  const tableIcons = {
    ArrowDownward: ArrowDownward,
    ArrowUpward: ArrowUpward,
    SortArrow: ArrowUpward,
    FirstPage: FirstPage,
    LastPage: LastPage,
    NextPage: ChevronRight,
    PreviousPage: ChevronLeft,
  }
  return (
    <React.Fragment>
      <GridItem xs={12}>
        <MaterialTable
          icons={tableIcons}
          options={{
            search: false,
            sorting: true,
            paging: true,
            pageSize: 10,
          }}
          tableLayout="auto"
          columns={[
            {
              title: 'Activity',
              field: 'registrationActivity',
              cellStyle: {
                paddingLeft: '12px',
              },
              headerStyle: {
                paddingLeft: '12px',
              },
            },
            {
              title: 'Activity Timestamp',
              field: 'registrationActivityTimeStamp',
              type: 'numeric',
              align: 'left',
              defaultSort: 'desc',
              render: (rowData) => (
                <span>
                  {moment(rowData.registrationActivityTimeStamp).format(
                    'MM-DD-YYYY  HH:mm:ss'
                  )}
                </span>
              ),
            },
            { title: 'User ID', field: 'createUser' },
          ]}
          data={props.registrationHistory}
          title=""
        />
      </GridItem>
    </React.Fragment>
  )
}
