import React from 'react'
import VendorSelectionContent from './VendorSelectionContent'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Heading } from '@enterprise-ui/canvas-ui-react'
import ErrorBoundary from '../../global/util/ErrorBoundary'

class VendorSelectionPanel extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ErrorBoundary>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Heading size={5}>Vendor Selection</Heading>
            </AccordionSummary>
            <AccordionDetails>
              <VendorSelectionContent {...this.props} />
            </AccordionDetails>
          </Accordion>
        </ErrorBoundary>
      </React.Fragment>
    )
  }
}

export default VendorSelectionPanel
