import React from 'react'
// WARNING: Canvas v7 - Component "Container" has been deprecated. Please remove this import.
import {
  Form,
  Grid,
  GridItem,
  Tooltip,
  Heading,
  Anchor,
  Button,
} from '@enterprise-ui/canvas-ui-react'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ValidateInput } from '../../global/util/ValidateStringUtil'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'

const operatingCompanyList = [
  { value: 4, label: 'Target Stores' },
  { value: 5, label: 'Target.com' },
  { value: 95, label: 'TGSL' },
]
class RegistrationStatus extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOperatingCompanyInValid: false,
      isEndDateInvalid: false,
      isStartDateInValid: false,
      partnerRegistrationType: 'EDI',
      expandOnPanelClick: true,
    }
    this.setDefaultStartAndEndDate = this.setDefaultStartAndEndDate.bind(this)
    this.handleSearchRequest = this.handleSearchRequest.bind(this)
  }

  componentDidMount() {
    this.setDefaultStartAndEndDate()
    if (this.props.redirect === true) {
      const searchParamsFromStorage = localStorage.getItem(
        'registrationSearchParams'
      )
      this.setState({
        operatingCompany: JSON.parse(searchParamsFromStorage).operatingCompany,
        companyId: JSON.parse(searchParamsFromStorage).companyId,
        status: JSON.parse(searchParamsFromStorage).status,
        senderId: JSON.parse(searchParamsFromStorage).senderId,
        qualifier: JSON.parse(searchParamsFromStorage).qualifier,
        startDate: JSON.parse(searchParamsFromStorage).startDate,
        endDate: JSON.parse(searchParamsFromStorage).endDate,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Accordion
          defaultExpanded
          expanded={this.state.expandOnPanelClick}
          style={{ overflow: 'visible', minHeight: '100%' }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                onClick={() => {
                  this.setState({
                    expandOnPanelClick: !this.state.expandOnPanelClick,
                  })
                }}
              />
            }
            onClick={() => {
              this.setState({
                expandOnPanelClick: !this.state.expandOnPanelClick,
              })
            }}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Heading size={5}>
              Partner Registration Status : Registration Search
            </Heading>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ padding: '0px' }}>
              <Grid.Container spacing="dense" style={{ padding: '0px' }}>
                <Grid.Item xs={3}>
                  <Form.Field
                    type="select"
                    id="Operating_company_list"
                    label="Operating Company"
                    errorText="Operating Company is required"
                    error={this.state.isOperatingCompanyInValid}
                    value={this.state.operatingCompany}
                    onUpdate={(id, value) => {
                      this.setState({
                        operatingCompany: value,
                        isOperatingCompanyInValid: false,
                      })
                    }}
                    required
                    options={operatingCompanyList}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    id="company_id"
                    label="Company ID/SCAC"
                    value={this.state.companyId}
                    error={ValidateInput(this.state.companyId)}
                    errorText="Cannot contain special characters or spaces."
                    onChange={(e) => {
                      this.setState({
                        companyId: e.target.value.toUpperCase(),
                      })
                    }}
                  />
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Form.Field
                    type="select"
                    id="type_list"
                    label="Type"
                    value={this.state.partnerRegistrationType}
                    onUpdate={(id, value) => {
                      console.log('Registration Type selected: ' + value)
                      this.setState({ partnerRegistrationType: value })
                    }}
                    required
                    options={[{ value: 'EDI', label: 'EDI' }]}
                  />
                </Grid.Item>
              </Grid.Container>

              <Grid.Container spacing="dense" style={{ padding: '0px' }}>
                <GridItem xs={3}>
                  <Form.Field
                    type="select"
                    id="status_list"
                    label="Status"
                    value={this.state.status}
                    onUpdate={(id, value) => {
                      console.log('selected status: ' + value)
                      this.setState({ status: value })
                    }}
                    options={[
                      { label: 'All' },
                      { value: 'Auto Approved', label: 'Auto Approved' },
                      {
                        value: 'Manually Approved',
                        label: 'Manually Approved',
                      },
                      { value: 'Pending', label: 'Pending' },
                      { value: 'Rejected', label: 'Rejected' },
                    ]}
                  />
                </GridItem>
                <GridItem xs={3} style={{ paddingRight: '0px' }}>
                  <Form.Field
                    id="sender_receiver_id"
                    label="Sender/Receiver ID"
                    maxLength="15"
                    value={this.state.senderId}
                    error={ValidateInput(this.state.senderId)}
                    errorText="Cannot contain special characters or spaces."
                    onChange={(e) => {
                      this.setState({
                        senderId: e.target.value.toUpperCase(),
                      })
                    }}
                  />
                </GridItem>

                <GridItem xs={2} style={{ paddingLeft: '12px' }}>
                  <Form.Field
                    id="qualifier"
                    label="Qualifier"
                    maxLength="2"
                    value={this.state.qualifier}
                    error={ValidateInput(this.state.qualifier)}
                    errorText="Cannot contain special characters or spaces."
                    onChange={(e) => {
                      this.setState({
                        qualifier: e.target.value.toUpperCase(),
                      })
                    }}
                  />
                </GridItem>
              </Grid.Container>
              <Grid.Container spacing="dense" style={{ padding: '0px' }}>
                <GridItem
                  xs={4}
                  spacing="dense"
                  style={{
                    paddingTop: '0px',
                    paddingRight: '50px',
                  }}
                >
                  <DatePicker
                    id="start_date"
                    label="Start Date"
                    required
                    value={this.state.startDate}
                    error={this.state.isStartDateInvalid}
                    errorText="Start Date should be before End Date"
                    onUpdate={(id, value) => {
                      this.setState({ isStartDateInvalid: false })
                      if (value) {
                        if (
                          Date.parse(value) <= Date.parse(this.state.endDate)
                        ) {
                          this.setState({ startDate: value })
                        } else {
                          this.setState({
                            isStartDateInvalid: true,
                          })
                        }
                      }
                    }}
                    rightContent={
                      <Tooltip
                        location="top-left"
                        content="Start Date should be before End Date"
                      >
                        <Anchor>What is this?</Anchor>
                      </Tooltip>
                    }
                    location="bottom-left"
                  />
                </GridItem>
                <GridItem
                  xs={4}
                  spacing="dense"
                  style={{ paddingTop: '0px', paddingRight: '50px' }}
                >
                  <DatePicker
                    required
                    id="end_date"
                    error={this.state.isEndDateInvalid}
                    errorText="End Date should be after Start Date and cannot be a future date."
                    label="End Date"
                    onUpdate={(id, value) => {
                      this.setState({ isEndDateInvalid: false })
                      if (value) {
                        if (
                          new Date() < Date.parse(value) ||
                          Date.parse(value) < Date.parse(this.state.startDate)
                        ) {
                          this.setState({ isEndDateInvalid: true })
                        } else {
                          this.setState({ endDate: value })
                        }
                      }
                    }}
                    value={this.state.endDate}
                    rightContent={
                      <Tooltip
                        location="top-left"
                        content="End Date should be after Start Date and cannot be a future date."
                      >
                        <Anchor>What is this?</Anchor>
                      </Tooltip>
                    }
                    location="bottom-left"
                  />
                </GridItem>
              </Grid.Container>
              <GridItem xs={12}>
                <Button type="secondary" onClick={this.handleSearchRequest}>
                  CONTINUE
                </Button>
              </GridItem>
            </div>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    )
  }

  setDefaultStartAndEndDate() {
    var currentDate = new Date()
    this.setState({
      endDate:
        currentDate.getMonth() +
        1 +
        '/' +
        currentDate.getDate() +
        '/' +
        currentDate.getFullYear(),
    })

    var startDate = new Date()
    startDate.setDate(startDate.getDate() - 30)
    this.setState({
      startDate:
        startDate.getMonth() +
        1 +
        '/' +
        startDate.getDate() +
        '/' +
        startDate.getFullYear(),
    })
  }

  handleSearchRequest() {
    if (this.state.operatingCompany === undefined) {
      this.setState({ isOperatingCompanyInValid: true })
    }
    // console.log(
    //   'form submitted with values: operating company: ' +
    //     this.state.operatingCompany +
    //     ' company Id: ' +
    //     this.state.companyId +
    //     ' Type: ' +
    //     this.state.type +
    //     ' Sender/Receiver Id: ' +
    //     this.state.senderId +
    //     ' Qualifier: ' +
    //     this.state.qualifier +
    //     ' Status: ' +
    //     this.state.status +
    //     ' Start Date: ' +
    //     this.state.startDate +
    //     ' Enda Date: ' +
    //     this.state.endDate
    // )
    if (
      this.state.operatingCompany !== undefined &&
      !this.state.isStartDateInValid &&
      !this.state.isEndDateInvalid
    ) {
      this.setState({
        expandOnPanelClick:
          this.state.expandOnPanelClick === true ? false : true,
      })
      localStorage.setItem(
        'registrationSearchParams',
        JSON.stringify(this.state)
      )
      this.props.showSearchResults(
        this.state.operatingCompany,
        this.state.companyId,
        this.state.partnerRegistrationType,
        this.state.senderId,
        this.state.qualifier,
        this.state.status,
        this.state.startDate,
        this.state.endDate
      )
    }
  }
}

export default RegistrationStatus
