import { Heading, Grid } from '@enterprise-ui/canvas-ui-react'
import * as RegistrationMaintenanceConstant from '../Constants'
import RegistrationHistory from './RegistrationHistory'
import moment from 'moment'

const Activity = (props) => {
  return (
    <div>
      <div className="hc-pt-dense hc-pb-expanded">
        <Heading size={5} className="hc-pt-dense hc-pb-dense">
          Registration Activity
        </Heading>
        Status:{' '}
        <span
          className={
            props.isRegistrationApproved ? 'hc-clr-success' : 'hc-clr-error'
          }
          style={{ fontWeight: 'bolder' }}
        >
          {props.registration?.registrationStatus}
        </span>
        {props.registration?.registrationStatus ===
        RegistrationMaintenanceConstant.REJECTED ? (
          <span>
            <br />
            Rejection Reason: {props.registration?.rejectionReason}
          </span>
        ) : (
          ''
        )}
        <br />
        Submitted By:{' ' + props.registration?.createUser}
        <br />
        Date Submitted:{' '}
        {moment(props.registration?.createTimeStamp).format(
          'MM-DD-YYYY HH:mm:ss'
        )}
        <br />
        Updated By:{' ' + props.registration?.updateUser}
        <br />
        Date Last Updated:{' '}
        {moment(props.registration?.updateTimeStamp).format(
          'MM-DD-YYYY HH:mm:ss'
        )}
        <br />
      </div>
      <Grid.Item xs={12}>
        <RegistrationHistory
          {...props}
          registrationGenId={props.registration?.registrationGenId}
        />
      </Grid.Item>
    </div>
  )
}

export default Activity
