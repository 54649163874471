import React from 'react'
import axios from 'axios'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  Form,
  Heading,
  Grid,
  Button,
  Anchor,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import AnchorEdit from './common/AnchorEdit'
import { ValidateInput } from '../../global/util/ValidateStringUtil'

class EdiSetup extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
    this.handleContinue = this.handleContinue.bind(this)
    this.EdiId = this.EdiId.bind(this)
    this.Qualifier = this.Qualifier.bind(this)
    this.EdiId2 = this.EdiId2.bind(this)
    this.Qualifier2 = this.Qualifier2.bind(this)
    this.AdditionalEdiIdSetupPanel = this.AdditionalEdiIdSetupPanel.bind(this)
    this.ContinueEditButton = this.ContinueEditButton.bind(this)
    this.editEdiIdInfo = this.editEdiIdInfo.bind(this)
  }

  getInitialState = () => ({
    ediId: '',
    qualifier: '',
    ediId2: '',
    qualifier2: '',
    userRegistrationObj: null,
    editMode: true,
    editModeInitialEdiIdQualifier: true,
    isAdditionalRegistrationSupported: true,
    ediIdError: false,
    qualifierError: false,
    ediIdError2: false,
    qualifierError2: false,
  })

  /* Loads the Vendor's existing Edi Id and Qualifier Data from Service */
  componentDidMount() {
    this.setState({
      isDataLoaded: false,
    })
    this.setState({ hasPendingRegistration: false })
    if (this.props.gmsVendorNumber && this.props.operatingCompany) {
      const EDI_ID_QUALIFIER_DATA_ENDPOINT =
        this.props.env.ess.url +
        '/api/v1/get-existing-ediId-qualifier?gmsVendorNumber=' +
        this.props.gmsVendorNumber +
        '&vendorId=' +
        this.props.vendorId +
        '&operatingCompany=' +
        this.props.operatingCompany +
        '&companyId=' +
        this.props.companyId +
        '&vendorName=' +
        this.props.vendorName +
        '&isTransportationVendor=' +
        this.props.vendorDetails.isTransportationVendor +
        '&businessPartnerRoleId=' +
        this.props.businessPartnerRoleId

      axios
        .get(EDI_ID_QUALIFIER_DATA_ENDPOINT)
        .then((response) => {
          var data = response.data
          if (data.errorCode === 'E001') {
            this.setState({
              hasPendingRegistration: true,
              pendingRegistrations: data.data[0],
            })
          } else {
            this.setState({
              ediId: data.ediId,
              qualifier: data.qualifier,
              isAdditionalRegistrationSupported:
                data.isAdditionalRegistrationSupported,
              isDataLoaded: true,
            })
            this.InitialEdiIdQualifier()
          }
        })
        .catch((error) => {
          if (error.response.status === 412) {
            console.log('handle a pending approval registration')
            if (error.response.data.errorCode === 'E001') {
              this.setState({
                hasPendingRegistration: true,
                pendingRegistrations: error.response.data.data[0],
              })
            }
          } else {
            this.props.handleException(
              'Unexpected Error occurred while trying to access the endpoint that extracts the latest EDI ID and Qualifier for a Vendor' +
                error
            )
          }
        })
    }
  }

  InitialEdiIdQualifier() {
    if (this.state.ediId === null && this.state.qualifier === null) {
      if (this.props.vendorDetails.isTransportationVendor === true) {
        this.setState({
          ediId:
            this.props.vendorDetails.vendor.vendor_gvi.transport_vendor
              .scac_code,
          qualifier: '02',
        })
      }
    } else {
      this.handleContinue()
      this.setState({ editModeInitialEdiIdQualifier: false })
    }
  }

  editEdiIdInfo() {
    this.setState({ editMode: true, editModeInitialEdiIdQualifier: true })
    this.props.onEdit()
  }

  handleContinue() {
    axios
      .get(
        this.props.env.ess.url +
          '/api/v1/validate-edi-id-setup?ediId=' +
          this.state.ediId +
          '&ediId2=' +
          this.state.ediId2 +
          '&gmsVendorNumber=' +
          this.props.gmsVendorNumber +
          '&vendorId=' +
          this.props.vendorId +
          '&qualifier=' +
          this.state.qualifier +
          '&qualifier2=' +
          this.state.qualifier2 +
          '&operatingCompany=' +
          this.props.operatingCompany +
          '&isTransportationVendor=' +
          this.props.vendorDetails.isTransportationVendor +
          '&companyId=' +
          this.props.companyId
      )
      .then((response) => {
        var data = response.data
        this.setState({
          userRegistrationObj: data,
        })
        if (
          this.state.userRegistrationObj &&
          this.state.userRegistrationObj.status
        ) {
          var status = this.state.userRegistrationObj.status
          if (this.state.ediId2 && this.state.qualifier2) {
            this.setState({
              ediId: this.state.ediId2,
              qualifier: this.state.qualifier2,
              ediId2: '',
              qualifier2: '',
            })
          } else if (!status.success && status.errorCode === 'E003') {
            this.setState({
              ediId2: '',
              qualifier2: '',
            })
          }
          if (status.success) {
            this.setState({
              editMode: false,
              editModeInitialEdiIdQualifier: false,
            })
          }
        }

        this.props.handleContinue(
          this.state.ediId,
          this.state.qualifier,
          this.state.userRegistrationObj
        )
      })
      .catch((error) =>
        this.props.handleException(
          'Unexpected error occurred while calling the ediIdSetup endpoint --> /api/v1/validate-edi-id-setup?ediId'
        )
      )
  }

  render() {
    return (
      <React.Fragment>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Heading size={5}>EDI Partner Info </Heading>
          </AccordionSummary>
          <AccordionDetails>
            <Grid.Item xs={4}>
              {this.state.hasPendingRegistration ? (
                <p className="ess_error" id="pending_registration_error">
                  This registration can’t be completed because our records
                  indicate there is a pending registration for this vendor
                  number. <br />
                  <br />
                  The details of the pending registration are:
                  <br />
                  EDI ID: {this.state.pendingRegistrations.ediPartnerId} <br />
                  Qualifier:{' '}
                  {this.state.pendingRegistrations.ediPartnerQualifier}
                  <br />
                  <br />
                  If you have questions or need further assistance, please
                  contact Target’s Electronic Commerce Service Center at{' '}
                  {this.props.env.ecsc.contact}.
                </p>
              ) : this.state.isDataLoaded &&
                this.props.userRoles &&
                !this.props.userRoles.includes(this.props.env.superUserRole) &&
                this.props.vendorDetails &&
                !this.state.isAdditionalRegistrationSupported ? (
                <p className="ess_error">
                  Based on the information submitted, there is an existing EDI
                  partnership for this vendor number.
                  <br />
                  If you have questions or need further assistance, please
                  contact Target’s Electronic Commerce Service Center at
                  <br />
                  {this.props.env.ecsc.contact}
                </p>
              ) : this.state.isDataLoaded ? (
                <div>
                  <this.EdiId />
                  <this.Qualifier />
                  <this.AdditionalEdiIdSetupPanel />
                  <this.ContinueEditButton />
                </div>
              ) : (
                ''
              )}
            </Grid.Item>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    )
  }

  AdditionalEdiIdSetupPanel() {
    if (
      (this.state.userRegistrationObj &&
        !this.state.userRegistrationObj.status.success &&
        this.state.userRegistrationObj.status.errorCode === 'E003') ||
      this.state.userRegistrationObj?.status?.errorCode === 'E004'
    ) {
      return (
        <p className="ess_error">
          Based on the information provided, the EDI ID entered has an existing
          EDI partnership. Please contact the Target’s Electronic Commerce
          Service Center at {this.props.env.ecsc.contact} for further
          assistance.
        </p>
      )
    } else if (
      this.state.userRegistrationObj &&
      !this.state.userRegistrationObj.status.success &&
      this.state.userRegistrationObj.status.errorCode === 'E002'
    ) {
      return (
        <React.Fragment>
          <p className="ess_error">
            An EDI partnership already exists based on the information provided.
            <br />
            If an additional partnership is needed, please enter a new EDI ID
            and qualifier below.
          </p>
          <this.EdiId2 />
          <this.Qualifier2 />
        </React.Fragment>
      )
    } else {
      return <React.Fragment></React.Fragment>
    }
  }

  ContinueEditButton() {
    if (this.state.editMode) {
      return (
        <Button
          size="dense"
          onClick={this.handleContinue}
          type="secondary"
          disabled={
            this.state.ediIdError ||
            this.state.qualifierError ||
            this.state.ediIdError2 ||
            this.state.qualifierError2 ||
            !this.state.ediId ||
            !this.state.qualifier
          }
        >
          Continue
        </Button>
      )
    }
    return (
      <AnchorEdit
        handleEdit={this.editEdiIdInfo}
        editMode={!this.state.editMode}
      />
    )
  }

  EdiId() {
    return (
      <Form.Field
        required
        id="edi_id"
        label="EDI ID"
        maxLength="15"
        value={this.state.ediId}
        error={this.state.ediIdError}
        errorText="Limit of 15 alphanumeric characters, cannot contain special characters or spaces."
        disabled={!this.state.editModeInitialEdiIdQualifier}
        rightContent={
          <Tooltip
            location="top-left"
            content="Sender/Receiver ID. Limit of 15 alphanumeric characters.  This information should be provided by your company’s EDI Service Provider or EDI team."
            tabIndex={-1}
          >
            <Anchor tabIndex={-1}>What is this?</Anchor>
          </Tooltip>
        }
        onChange={(e) => {
          this.setState({
            ediIdError: ValidateInput(e.target.value),
            ediId: e.target.value.toUpperCase(),
          })
        }}
      />
    )
  }

  Qualifier() {
    return (
      <Form.Field
        required
        id="qualifier"
        label="Qualifier"
        maxLength="2"
        value={this.state.qualifier}
        error={this.state.qualifierError}
        errorText="Must be 2 characters, cannot contain special characters or spaces."
        disabled={!this.state.editModeInitialEdiIdQualifier}
        rightContent={
          <Tooltip
            location="top-left"
            content="Qualifier ID. Must be 2 alphanumeric characters.  This information should be provided by your company’s EDI Service Provider or EDI team."
            tabIndex={-1}
          >
            <Anchor tabIndex={-1}>What is this?</Anchor>
          </Tooltip>
        }
        onChange={(e) => {
          this.setState({
            qualifierError: ValidateInput(e.target.value),
            qualifier: e.target.value.toUpperCase(),
          })
        }}
      />
    )
  }

  EdiId2() {
    return (
      <Form.Field
        required
        id="edi_id2"
        label="Additional EDI ID"
        maxLength="15"
        value={this.state.ediId2}
        error={this.state.ediIdError2}
        errorText="Limit of 15 alphanumeric characters, cannot contain special characters or spaces."
        disabled={!this.state.editMode}
        rightContent={
          <Tooltip
            location="top-left"
            content="Sender/Receiver ID. Limit of 15 alphanumeric characters.  This information should be provided by your company’s EDI Service Provider or EDI team."
            tabIndex={-1}
          >
            <Anchor tabIndex={-1}>What is this?</Anchor>
          </Tooltip>
        }
        onChange={(e) => {
          this.setState({
            ediIdError2: ValidateInput(e.target.value),
            ediId2: e.target.value.toUpperCase(),
          })
        }}
      />
    )
  }

  Qualifier2() {
    return (
      <Form.Field
        required
        id="qualifier2"
        label="Additional Qualifier"
        maxLength="2"
        value={this.state.qualifier2}
        error={this.state.qualifierError2}
        errorText="Must be 2 characters, cannot contain special characters or spaces."
        disabled={!this.state.editMode}
        rightContent={
          <Tooltip
            location="top-left"
            content="Qualifier ID. Must be 2 alphanumeric characters.  This information should be provided by your company’s EDI Service Provider or EDI team."
            tabIndex={-1}
          >
            <Anchor tabIndex={-1}>What is this?</Anchor>
          </Tooltip>
        }
        onChange={(e) => {
          this.setState({
            qualifierError2: ValidateInput(e.target.value),
            qualifier2: e.target.value.toUpperCase(),
          })
        }}
      />
    )
  }
}

export default EdiSetup
