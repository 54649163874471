import React from 'react'
import axios from 'axios'
import { trackPromise } from 'react-promise-tracker'
import {
  GridContainer,
  Grid,
  Breadcrumbs,
} from '@enterprise-ui/canvas-ui-react'
import RegistrationSummary from '../../registration/RegistrationSummary'
import RegistrationActivity from './RegistrationActivity'
import ResendRegistrationConfirmationEmail from './ResendRegistrationConfirmationEmail'
import AcceptOrRejectNonApprovedRegistration from './AcceptOrRejectNonApprovedRegistration'
import * as MaintenanceConstants from '../Constants'
import * as BreadcrumbConstants from '../../registration/common/BreadCrumbConstants'

class RegistrationDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      registration: [],
      dataLoaded: false,
    }
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search)
    const registrationId = query.get('registrationId')
    console.log(
      'registrationId in registartion-details page: ' + registrationId
    )
    const REGISTRATION_DETAILS =
      this.props.env.ess.url +
      '/api/v1/registration-details?registrationGenId=' +
      registrationId
    this.setState({ serviceError: false })
    trackPromise(
      axios.get(REGISTRATION_DETAILS).then((response) => {
        var data = response.data
        this.setState({
          registration: data,
          dataLoaded: true,
          isRegistrationApproved: this.isRegistrationApproved(data),
        })
      })
    )
  }

  isRegistrationApproved(registration) {
    return (
      registration.registrationStatus === MaintenanceConstants.AUTO_APPROVED ||
      registration.registrationStatus === MaintenanceConstants.MANUALLY_APPROVED
    )
  }

  render() {
    return this.state.dataLoaded ? (
      <div>
        <Breadcrumbs>
          <Breadcrumbs.Item href="/">
            {BreadcrumbConstants.HOME_CRUMB}
          </Breadcrumbs.Item>
          <Breadcrumbs.Item href="/registration/status?redirect=true&content=search">
            {BreadcrumbConstants.REGISTRATION_SEARCH_CRUMB}
          </Breadcrumbs.Item>
          <Breadcrumbs.Item href="/registration/status?redirect=true&content=results">
            {BreadcrumbConstants.REGISTRATION_SEARCH_RESULTS_CRUMB}
          </Breadcrumbs.Item>
          <Breadcrumbs.Item>
            {BreadcrumbConstants.REGISTRATION_DETAILS_CRUMB}
          </Breadcrumbs.Item>
        </Breadcrumbs>
        <GridContainer style={{ padding: '50px' }}>
          <Grid.Item xs={12}>
            <RegistrationSummary
              {...this.state}
              {...this.props}
              showSegmentInfo
              operatingCompany={this.state.registration.operatingCompany}
              registrationTypeId={this.state.registration.registrationTypeId}
              ediId={this.state.registration.ediPartnerId}
              qualifier={this.state.registration.ediPartnerQualifier}
              vendorName={this.state.registration.partnerName}
              vendorNumber={this.state.registration.gmsVendorNumber}
              isTransportation={
                this.state.registration.transportationFlag === 'Y'
                  ? true
                  : false
              }
              scacCode={this.state.registration.companyId}
              ecomVendorNumber={this.state.registration.companyId}
              selectedServiceProvider={this.state.registration.serviceProvider}
              selectedRegistrationType={
                this.state.registration.registrationType
              }
              poFormat={this.state.registration.poFormat}
              returnRegistrationDocuments
              getRegistrationTypeDocuments={(registrationTypeDocuments) => {
                this.setState({
                  registrationTypesDoc: registrationTypeDocuments,
                })
              }}
            />
            <RegistrationActivity
              {...this.props}
              registration={this.state.registration}
              isRegistrationApproved={this.state.isRegistrationApproved}
            />
          </Grid.Item>
          {this.state.isRegistrationApproved ? (
            <ResendRegistrationConfirmationEmail
              {...this.props}
              userInfo={this.props.userInfo}
              registration={this.state.registration}
              registrationTypesDoc={this.state.registrationTypesDoc}
            />
          ) : (
            <AcceptOrRejectNonApprovedRegistration
              {...this.props}
              registration={this.state.registration}
              registrationTypesDoc={this.state.registrationTypesDoc}
              updateRegistration={(updatedRegistration) => {
                this.setState({
                  registration: updatedRegistration,
                  isRegistrationApproved:
                    this.isRegistrationApproved(updatedRegistration),
                })
              }}
            />
          )}
        </GridContainer>
      </div>
    ) : (
      ''
    )
  }
}
export default RegistrationDetails
