import React from 'react'
import axios from 'axios'
import { Grid, FormField, Button } from '@enterprise-ui/canvas-ui-react'
import * as RegistrationMaintenanceConstant from '../Constants'
import Logger from '../../../global/util/Logger'
import {
  LOGGER_LEVEL_INFO,
  LOGGER_LEVEL_ERROR,
} from '../../registration/common/Constants'
import { trackPromise } from 'react-promise-tracker'

class AcceptOrRejectNonApprovedRegistration extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      registrationStatus: '',
      isMissingRejectedReason: false,
      rejectionReason: '',
      formSubmitted: false,
      registration: this.props.registration,
    }
    this.submitRegistrationStatusChange =
      this.submitRegistrationStatusChange.bind(this)
  }

  render() {
    const AcceptRejectOptions = [
      {
        value: RegistrationMaintenanceConstant.MANUALLY_APPROVED,
        label: 'Accept',
      },
      {
        value: RegistrationMaintenanceConstant.REJECTED,
        label: 'Reject',
        disabled:
          this.state.registration.registrationStatus ===
          RegistrationMaintenanceConstant.REJECTED,
      },
    ]
    return (
      <Grid.Item xs={12}>
        <FormField
          required
          label="Accept/Reject"
          type="radio"
          id="accept_reject"
          value={this.state.registrationStatus}
          disabled={this.state.formSubmitted}
          options={AcceptRejectOptions}
          onUpdate={(e, a) => {
            this.setState({ registrationStatus: a })
          }}
        />
        {this.state.registrationStatus ===
        RegistrationMaintenanceConstant.REJECTED ? (
          <Grid.Item xs={8}>
            <FormField
              required
              label="Rejection Reason"
              value={this.state.rejectionReason}
              type="textarea"
              id="rejection_reason"
              disabled={this.state.formSubmitted}
              error={this.state.isMissingRejectedReason}
              errorText="You must put in a rejection reason"
              onChange={(e) => {
                this.setState({ rejectionReason: e.target.value })
              }}
            />
          </Grid.Item>
        ) : (
          ''
        )}
        <Button
          spacing="dense"
          type="secondary"
          disabled={this.state.formSubmitted}
          onClick={this.submitRegistrationStatusChange}
        >
          Submit
        </Button>
        <span
          style={{ fontWeight: 'bold' }}
          className={
            this.state.submissionError ? 'hc-clr-error' : 'hc-clr-success'
          }
        >
          <br />
          <br />

          {this.state.submissionError === true
            ? 'There was an issue submitting the request.'
            : ''}
          {this.state.submissionError === false
            ? 'The change has been succesfully updated in the system.'
            : ''}
        </span>
      </Grid.Item>
    )
  }

  submitRegistrationStatusChange() {
    if (
      this.state.registrationStatus === RegistrationMaintenanceConstant.REJECTED
    ) {
      if (this.state.rejectionReason.trim().length === 0) {
        this.setState({ isMissingRejectedReason: true })
        return
      } else {
        this.setState({ isMissingRejectedReason: false })
      }
      var userRegistration = {}
      userRegistration.userLanId = this.props.userInfo.lanId
      userRegistration.serviceProvider = this.state.registration.serviceProvider
      userRegistration.ediId = this.state.registration.ediPartnerId
      userRegistration.qualifier = this.state.registration.ediPartnerQualifier
      userRegistration.operatingCompany =
        this.state.registration.operatingCompany
      userRegistration.registrationGenId =
        this.state.registration.registrationGenId
      userRegistration.companyId = this.state.registration.companyId
      userRegistration.vendorName = this.state.registration.partnerName
      userRegistration.gmsVendorNumber = this.state.registration.gmsVendorNumber
      if (this.state.registration.transportationFlag === 'Y') {
        userRegistration.isTransportationVendor = true
        userRegistration.scacCode = this.state.registration.companyId
      }
      userRegistration.rejectedReason = this.state.rejectionReason
      const REGISTRATION_REJECTION_ENDPOINT = '/api/v1/registration-rejection'
      const requestOptions = {
        method: 'post',
        url: this.props.env.ess.url + REGISTRATION_REJECTION_ENDPOINT,
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(userRegistration),
      }
      Logger(
        'RegistrationConfirmation - registration being submitted with request options: ' +
          requestOptions.body,
        LOGGER_LEVEL_INFO,
        this.props.env.ess.url + REGISTRATION_REJECTION_ENDPOINT
      )

      this.setState({
        formSubmitted: true,
      })
      trackPromise(
        axios(requestOptions)
          .then((response) => {
            var data = response.data
            console.log('Edi Registration Rejection: ' + data)
            this.props.updateRegistration(data)
            this.setState({ formSubmitted: true, submissionError: false })
            Logger(
              'RegistrationSubmitted',
              LOGGER_LEVEL_INFO,
              this.props.env.ess.url + REGISTRATION_REJECTION_ENDPOINT
            )
          })
          .catch((error) => {
            this.setState({
              submissionError: true,
              formSubmitted: false,
              isLoading: false,
            })
            Logger(
              'RegistrationSubmission Failed with error: ' + error,
              LOGGER_LEVEL_ERROR,
              this.props.env.ess.url + REGISTRATION_REJECTION_ENDPOINT
            )
          })
      )
    } else if (
      this.state.registrationStatus ===
      RegistrationMaintenanceConstant.MANUALLY_APPROVED
    ) {
      userRegistration = {}
      userRegistration.userLanId = this.props.userInfo.lanId
      userRegistration.serviceProvider = this.state.registration.serviceProvider
      userRegistration.ediId = this.state.registration.ediPartnerId
      userRegistration.qualifier = this.state.registration.ediPartnerQualifier
      userRegistration.operatingCompany =
        this.state.registration.operatingCompany
      userRegistration.registrationGenId =
        this.state.registration.registrationGenId
      userRegistration.companyId = this.state.registration.companyId
      userRegistration.vendorName = this.state.registration.partnerName
      userRegistration.superUserFlag = this.state.registration.superUserFlag
      userRegistration.gmsVendorNumber = this.state.registration.gmsVendorNumber
      if (this.state.registration.transportationFlag === 'Y') {
        userRegistration.isTransportationVendor = true
        userRegistration.scacCode = this.state.registration.companyId
      }
      userRegistration.rejectedReason = null
      userRegistration.registrationTypeId =
        this.state.registration.registrationTypeId
      userRegistration.registrationType =
        this.state.registration.registrationType
      userRegistration.userRoles = this.props.userRoles
      userRegistration.registrationTypeDocuments =
        this.props.registrationTypesDoc
      userRegistration.businessPartnerContact =
        this.state.selectedBusinessPartnerContact
      userRegistration.purchaseOrderFormat = this.state.registration.poFormat

      const REGISTRATION_MANUALLY_APPROVED_ENDPOINT =
        '/api/v1/registration-manually-approved'

      const requestOptions = {
        method: 'post',
        url: this.props.env.ess.url + REGISTRATION_MANUALLY_APPROVED_ENDPOINT,
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(userRegistration),
      }
      Logger(
        'RegistrationConfirmation - registration being submitted with request options: ' +
          requestOptions.body,
        LOGGER_LEVEL_INFO,
        this.props.env.ess.url + REGISTRATION_MANUALLY_APPROVED_ENDPOINT
      )
      this.setState({
        formSubmitted: true,
      })
      trackPromise(
        axios(requestOptions)
          .then((response) => {
            var data = response.data
            console.log('Edi Registration Manually Approved: ' + data)
            this.props.updateRegistration(data)
            this.setState({ formSubmitted: true, submissionError: false })
            Logger(
              'RegistrationSubmitted',
              LOGGER_LEVEL_INFO,
              this.props.env.ess.url + REGISTRATION_MANUALLY_APPROVED_ENDPOINT
            )
          })
          .catch((error) => {
            this.setState({
              submissionError: true,
              formSubmitted: false,
              isLoading: false,
            })
            Logger(
              'RegistrationSubmission failed with error: ' + error,
              LOGGER_LEVEL_ERROR,
              this.props.env.ess.url + REGISTRATION_MANUALLY_APPROVED_ENDPOINT
            )
          })
      )
    }
  }
}

export default AcceptOrRejectNonApprovedRegistration
