import React from 'react'
import axios from 'axios'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Heading, Grid, FormField, Table } from '@enterprise-ui/canvas-ui-react'
import AnchorEdit from './common/AnchorEdit'

class RegistrationTypeSelectionPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isAdditionalRegistration: false,
      selectedRegistrationType: null,
      registrationTypes: [],
      gmsVendorNumber: this.props.gmsVendorNumber,
      isRegistrationTypeSelected: false,
    }
    this.handleRegistrationTypeChange =
      this.handleRegistrationTypeChange.bind(this)
    this.editRegistrationTypeSelection =
      this.editRegistrationTypeSelection.bind(this)
  }

  /* Loads Registration Type Data from api when the component renders*/
  componentDidMount() {
    this.setState({
      isDataLoaded: false,
    })
    console.log(
      'businessPartnerRoleId: ' +
        this.props.businessPartnerRoleId +
        ' gmsVendorNumber: ' +
        this.state.gmsVendorNumber +
        ' operatingCompany: ' +
        this.props.operatingCompany +
        ' userRoles: ' +
        this.props.userRoles +
        ' isTransportationVendor: ' +
        this.props.isTransportationVendor +
        ' companyId: ' +
        this.props.companyId
    )
    const REGISTRATION_TYPE_ENDPOINT =
      this.props.env.ess.url +
      '/api/v1/registration-type?businessPartnerRoleId=' +
      this.props.businessPartnerRoleId +
      '&gmsVendorNumber=' +
      this.props.gmsVendorNumber +
      '&vendorId=' +
      this.props.vendorId +
      '&operatingCompany=' +
      this.props.operatingCompany +
      '&userRoles=' +
      this.props.userRoles +
      '&isTransportationVendor=' +
      this.props.isTransportationVendor +
      '&companyId=' +
      this.props.companyId
    axios
      .get(REGISTRATION_TYPE_ENDPOINT)
      .then((response) => {
        var data = response.data
        this.setState({
          registrationTypes: data,
          isDataLoaded: true,
        })
      })
      .catch((error) => {
        console.log(
          ' Unexpected Error occurred while trying to access the Registration Type Endpoint' +
            REGISTRATION_TYPE_ENDPOINT +
            ' Error ' +
            error
        )
        this.setState({ error, isLoading: false })
      })
  }

  handleRegistrationTypeChange(registrationType) {
    this.setState({
      isRegistrationTypeSelected: true,
      isEditing: false,
      selectedRegistrationType: registrationType,
    })
    this.props.handleRegistrationTypeChange(registrationType)
    window.location.href = '#registration_form'
  }

  editRegistrationTypeSelection() {
    this.setState({
      isRegistrationTypeSelected: false,
      isEditing: true,
    })
    this.props.onEdit()
  }

  render() {
    return (
      <React.Fragment>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Heading size={5}>Registration Type Selection</Heading>
          </AccordionSummary>
          <AccordionDetails>
            <Grid.Container>
              <Grid.Item xs={12}>
                <p style={{ paddingBottom: '40px' }}>
                  Choose the registration type which contains the documents you
                  will be exchanging with Target:{' '}
                </p>
                <div id="registrationTypesContent">
                  <Table id="registrationTypesTable">
                    <Table.Head>
                      <Table.Row>
                        <Table.Header xs={1}></Table.Header>
                        <Table.Header xs={3}>Registration Type</Table.Header>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {this.state.registrationTypes &&
                        this.state.registrationTypes.map((registrationType) => {
                          return (
                            <Table.Row
                              style={
                                registrationType ===
                                this.state.selectedRegistrationType
                                  ? { background: '#d6d6d6' }
                                  : { background: 'transparent' }
                              }
                              key={registrationType.registrationTypeId}
                            >
                              <Table.Data xs={1}>
                                <FormField
                                  className={
                                    registrationType ===
                                    this.state.selectedRegistrationType
                                      ? 'selected-table-record'
                                      : ' '
                                  }
                                  type="radio"
                                  value={this.state.selectedRegistrationType}
                                  id={registrationType.registrationTypeId}
                                  options={[
                                    {
                                      value: registrationType,
                                      label: '',
                                    },
                                  ]}
                                  onUpdate={(id, value) => {
                                    console.log('Changed!', value)
                                    this.handleRegistrationTypeChange(value)
                                  }}
                                  disabled={
                                    this.state.isRegistrationTypeSelected
                                  }
                                />
                              </Table.Data>
                              <Table.Data xs={11}>
                                {registrationType.additionalVerbiage
                                  ? registrationType.registrationTypeName +
                                    ' - ' +
                                    registrationType.additionalVerbiage
                                  : registrationType.registrationTypeName}
                              </Table.Data>
                            </Table.Row>
                          )
                        })}
                    </Table.Body>
                  </Table>
                  <br />
                  <AnchorEdit
                    editMode={this.state.isRegistrationTypeSelected}
                    handleEdit={this.editRegistrationTypeSelection}
                    isEditing={this.state.isEditing}
                    handleContinue={() => {
                      this.handleRegistrationTypeChange(
                        this.state.selectedRegistrationType
                      )
                    }}
                  />
                </div>
              </Grid.Item>
            </Grid.Container>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    )
  }
}

export default RegistrationTypeSelectionPanel
