import React from 'react'
import { GridItem, Breadcrumbs } from '@enterprise-ui/canvas-ui-react'
import RegistrationStatusFrom from './StatusForm'
import RegistrationStatusResults from './RegistrationStatusResults'
import { ErrorBoundary } from '@praxis/component-logging'
import * as BreadcrumbConstants from '../registration/common/BreadCrumbConstants'

class RegistrationStatus extends React.Component {
  constructor(props) {
    super(props)
    this.showSearchResults = this.showSearchResults.bind(this)
    this.state = { formSubmitted: false, count: 0 }
  }
  componentDidMount() {
    this.checkIfRedirectedRequest()
  }

  checkIfRedirectedRequest() {
    const query = new URLSearchParams(window.location.search)
    if (query.get('redirect') === 'true') {
      this.setState({
        redirect: true,
        loadRequestFromCache: true,
        formSubmitted: true,
      })
      if (query.get('content') === 'search') {
        window.location.href = '#registration_search'
      } else if (query.get('content') === 'results') {
        window.location.href = '#registration_search_results'
      }
    }
  }

  render() {
    return (
      <div>
        {this.state.formSubmitted ? (
          <Breadcrumbs>
            <Breadcrumbs.Item href="/">
              {BreadcrumbConstants.HOME_CRUMB}
            </Breadcrumbs.Item>
            <Breadcrumbs.Item
              onClick={() => {
                window.location.href = '#registration_search'
              }}
            >
              {BreadcrumbConstants.REGISTRATION_SEARCH_CRUMB}
            </Breadcrumbs.Item>
            <Breadcrumbs.Item
              onClick={() => {
                window.location.href = '#registration_search_results'
              }}
            >
              {BreadcrumbConstants.REGISTRATION_SEARCH_RESULTS_CRUMB}
            </Breadcrumbs.Item>
          </Breadcrumbs>
        ) : (
          <Breadcrumbs>
            <Breadcrumbs.Item href="/">
              {BreadcrumbConstants.HOME_CRUMB}
            </Breadcrumbs.Item>
            <Breadcrumbs.Item>
              {BreadcrumbConstants.REGISTRATION_SEARCH_CRUMB}
            </Breadcrumbs.Item>
          </Breadcrumbs>
        )}
        <GridItem
          xs={12}
          id="registration_search"
          style={{ paddingTop: '50px', overflow: 'visible' }}
        >
          <RegistrationStatusFrom
            redirect={this.state.redirect}
            key={this.state.redirect}
            showSearchResults={this.showSearchResults}
          />
        </GridItem>
        <div id="registration_search_results">
          {this.state.formSubmitted ? (
            <GridItem
              xs={12}
              style={{ paddingTop: '30px', paddingBottom: '20px' }}
            >
              <ErrorBoundary>
                <RegistrationStatusResults
                  {...this.state}
                  key={this.state.count}
                  env={this.props.env}
                />
              </ErrorBoundary>
            </GridItem>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }

  showSearchResults(
    operatingCompany,
    companyId,
    type,
    senderId,
    qualifier,
    status,
    startDate,
    endDate
  ) {
    this.setState({
      count: this.state.count + 1,
      formSubmitted: true,
      loadRequestFromCache: false,
      operatingCompany: operatingCompany,
      startDate: startDate,
      endDate: endDate,
      companyId: companyId,
      ediPartnerId: senderId,
      ediPartnerQualifier: qualifier,
      partnerRegistrationType: type,
      registrationStatus: status,
    })
  }
}

export default RegistrationStatus
