import React from 'react'
import axios from 'axios'
import {
  Table,
  Heading,
  GridItem,
  InputCheckbox,
  Button,
} from '@enterprise-ui/canvas-ui-react'
import { Accordion, AccordionSummary } from '@material-ui/core'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { trackPromise } from 'react-promise-tracker'
import './BusinessPartnerContacts.css'
import * as ErrorConstants from './common/ErrorConstants'
import { RegistrationError } from './common/RegistrationError'
import AnchorEdit from './common/AnchorEdit'

class BusinessPartnerContact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      businessPartnerContacts: [],
      selectedContacts: [],
      contactSelectionDisabled: false,
      editMode: true,
      isEditing: false,
    }
    this.handleContactSelection = this.handleContactSelection.bind(this)
    this.handleContinue = this.handleContinue.bind(this)
    this.editContactSelection = this.editContactSelection.bind(this)
    this.ContinueEditButton = this.ContinueEditButton.bind(this)
  }

  /* Loads Business Partner Contacts from Service */
  componentDidMount() {
    this.setState({
      isLoading: true,
      serviceError: false,
    })
    let config = {
      headers: {
        'Id-Token': window.localStorage.id_token,
      },
    }
    const BUSINESS_PARTNER_CONTACT_ENDPOINT =
      this.props.env.ess.url +
      '/api/v1/business-partner-contacts?gmsVendorNumber=' +
      this.props.gmsVendorNumber
    trackPromise(
      axios
        .get(BUSINESS_PARTNER_CONTACT_ENDPOINT, config)
        .then((response) => {
          var data = response.data
          this.setState({
            businessPartnerContacts: data,
            isLoading: false,
          })
        })
        .catch((error) => {
          console.log(
            'getting Business Partner contacts failed with error: ' + error
          )
          this.setState({ serviceError: true, isLoading: false })
        })
    )
  }

  handleContactSelection(contact, event) {
    const maxNoOfContactSelectionAllowed = 3
    let newContactsList = this.state.selectedContacts
    if (event.target.checked) {
      newContactsList.push(contact)
    } else {
      const filteredContactList = newContactsList.filter(
        (currentContact) => currentContact !== contact
      )
      newContactsList = filteredContactList
    }
    this.setState({ selectedContacts: newContactsList })
    if (newContactsList.length < maxNoOfContactSelectionAllowed) {
      this.setState({
        contactSelectionDisabled: false,
      })
    } else {
      this.setState({
        contactSelectionDisabled: true,
      })
    }
  }

  handleContinue() {
    this.setState({ editMode: false })
    this.props.onContactTypeChange(this.state.selectedContacts)
  }

  editContactSelection() {
    this.setState({ editMode: true, isEditing: true })
    this.props.onEdit()
  }

  ContinueEditButton() {
    if (this.state.editMode) {
      return (
        <Button size="dense" onClick={this.handleContinue} type="secondary">
          Continue
        </Button>
      )
    }
    return (
      <AnchorEdit
        editMode={!this.state.editMode}
        handleEdit={this.editContactSelection}
        isEditing={this.state.isEditing}
      />
    )
  }

  render() {
    return (
      <React.Fragment>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Heading size={5}>Contact Info</Heading>
          </AccordionSummary>
          <AccordionDetails>
            <GridItem xs={12}>
              <p style={{ paddingBottom: '40px' }}>
                Select up to 3 contacts that will be used for EDI Registration
                correspondence. If any of this information is not correct,
                please update your company’s contact information in VM (Vendor
                Management) before proceeding.{' '}
              </p>
              <div id="contactsContent">
                {this.state.isLoading ? (
                  'Data is loading...'
                ) : this.state.serviceError ? (
                  <RegistrationError
                    inError={this.state.serviceError}
                    message={ErrorConstants.VMM_ERROR_MESSAGE}
                  />
                ) : this.state.businessPartnerContacts &&
                  this.state.businessPartnerContacts.length > 0 ? (
                  <Table id="contactsTable">
                    <Table.Head>
                      <Table.Row>
                        <Table.Header xs={1}></Table.Header>
                        <Table.Header xs={3}>Name</Table.Header>
                        <Table.Header xs={2}>Contact Type</Table.Header>
                        <Table.Header xs={3}>Email Address</Table.Header>
                        <Table.Header xs={2}>Phone Number</Table.Header>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {this.state.businessPartnerContacts &&
                        this.state.businessPartnerContacts.map(
                          (businessPartnerContact) => {
                            return (
                              <Table.Row
                                style={
                                  this.state.selectedContacts.includes(
                                    businessPartnerContact
                                  )
                                    ? { background: '#d6d6d6' }
                                    : { background: 'transparent' }
                                }
                                key={businessPartnerContact.emailAddress}
                              >
                                <Table.Data xs={1}>
                                  <InputCheckbox
                                    disabled={
                                      (this.state.contactSelectionDisabled &&
                                        !this.state.selectedContacts.includes(
                                          businessPartnerContact
                                        )) ||
                                      !this.state.editMode
                                    }
                                    onChange={(event) => {
                                      console.log(
                                        JSON.stringify(businessPartnerContact)
                                      )
                                      this.handleContactSelection(
                                        businessPartnerContact,
                                        event
                                      )
                                    }}
                                  />
                                </Table.Data>
                                <Table.Data xs={3}>
                                  {businessPartnerContact.firstName}{' '}
                                  {businessPartnerContact.lastName}
                                </Table.Data>
                                <Table.Data xs={2}>
                                  {businessPartnerContact.responsibility !== ''
                                    ? businessPartnerContact.responsibility
                                    : businessPartnerContact.isAdmin}
                                </Table.Data>
                                <Table.Data xs={3}>
                                  <ul className="business-contacts-list">
                                    {businessPartnerContact.emailAddress.map(
                                      (emailAddress) => {
                                        return (
                                          <li key={emailAddress}>
                                            {emailAddress}
                                          </li>
                                        )
                                      }
                                    )}
                                  </ul>
                                </Table.Data>
                                <Table.Data xs={2}>
                                  <ul className="business-contacts-list">
                                    {businessPartnerContact.phone.map(
                                      (phone) => {
                                        return <li key={phone}>{phone}</li>
                                      }
                                    )}
                                  </ul>
                                </Table.Data>
                              </Table.Row>
                            )
                          }
                        )}
                    </Table.Body>
                  </Table>
                ) : (
                  <RegistrationError
                    inError={true}
                    message={ErrorConstants.VMM_NO_CONTACTS_AVAILABLE}
                  />
                )}
              </div>
              <br />
              <this.ContinueEditButton />
            </GridItem>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    )
  }
}

export default BusinessPartnerContact
