import React from 'react'
import { Button, Tooltip } from '@enterprise-ui/canvas-ui-react'

class AnchorEdit extends React.Component {
  constructor(props) {
    super(props)
    this.edit = this.edit.bind(this)
    this.continue = this.continue.bind(this)
  }

  edit() {
    this.props.handleEdit()
  }

  continue() {
    this.props.handleContinue()
  }

  render() {
    if (this.props.editMode) {
      return (
        <React.Fragment>
          <Tooltip
            location="top-left"
            content="Edit this content and all subsequent content"
          >
            <Button
              onClick={this.edit}
              className="ess-reg-edit"
              type="secondary"
              size="dense"
            >
              Edit
            </Button>
          </Tooltip>
        </React.Fragment>
      )
    }
    if (this.props.isEditing) {
      return (
        <React.Fragment>
          <Tooltip location="top-left" content="continue">
            <Button
              onClick={this.continue}
              className="ess-reg-edit"
              type="secondary"
              size="dense"
            >
              Continue
            </Button>
          </Tooltip>
        </React.Fragment>
      )
    }
    return <React.Fragment />
  }
}

export default AnchorEdit
