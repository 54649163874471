import React from 'react'
import { Grid, Table, Anchor } from '@enterprise-ui/canvas-ui-react'

class EdiGuidelineInformation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      guidelineUrlState: true,
    }
    this.registrationApproval = this.registrationApproval.bind(this)
    this.ediGuideline = this.ediGuideline.bind(this)
    this.handleGuidelineUrlByDocClick =
      this.handleGuidelineUrlByDocClick.bind(this)
    this.handleTargetEDIGuidelinesLinkClick =
      this.handleTargetEDIGuidelinesLinkClick.bind(this)
    this.targetEdiGuidelinesLink = this.targetEdiGuidelinesLink.bind(this)
  }

  handleGuidelineUrlByDocClick(guidelineUrl) {
    if (this.state.guidelineUrlState) {
      this.setState({ guidelineUrlState: true })
      window.location.href = guidelineUrl
    } else {
      this.setState({ guidelineUrlState: false })
    }
  }

  handleTargetEDIGuidelinesLinkClick() {
    window.location.href = this.props.ediGuideline[0].guidelineUrl
  }

  registrationApproval() {
    if (this.props.selectedServiceProvider.autoApprovedFlag === 'N') {
      return this.manualApprovalVerbiage()
    } else {
      return this.autoApprovalVerbiage()
    }
  }

  manualApprovalVerbiage() {
    return (
      <React.Fragment>
        <div id="manual_approval">
          <p className="hc-pt-dense hc-pb-expanded">
            The EDI Registration has been submitted and will be reviewed within
            2 business days. A confirmation email has been sent.
          </p>
          <p className="hc-pt-dense hc-pb-expanded">
            A separate email notification will be sent once the EDI Registration
            has been approved. If you do not receive notification within 3
            business days, please contact Target’s Electronic Commerce Service
            Center at {this.props.env.ecsc.contact}.
          </p>
          <this.targetEdiGuidelinesLink />
        </div>
      </React.Fragment>
    )
  }

  autoApprovalVerbiage() {
    return (
      <React.Fragment>
        <div id="auto_approval">
          <p className="hc-pt-dense hc-pb-expanded">
            The EDI Registration has been approved. A confirmation email has
            been sent.
          </p>
          <this.targetEdiGuidelinesLink />
        </div>
      </React.Fragment>
    )
  }

  targetEdiGuidelinesLink() {
    return (
      <React.Fragment>
        <p className="hc-pt-dense hc-pb-expanded">
          Please refer to the{' '}
          <span className="ess-font-bold">
            <Anchor
              onClick={this.handleTargetEDIGuidelinesLinkClick}
              className="target_edi_guidelines_link"
            >
              Target EDI Guidelines
            </Anchor>
          </span>{' '}
          for the following documents to ensure data can be sent and received in
          the format Target requires:
        </p>
      </React.Fragment>
    )
  }

  ediGuideline() {
    return (
      <React.Fragment>
        <Table>
          <Table.Body>
            {this.props.ediGuideline &&
              this.props.ediGuideline.map((ediGuideline) => {
                return (
                  <Table.Row
                    key={ediGuideline.guidelineId}
                    clickable
                    onClick={() => {
                      this.handleGuidelineUrlByDocClick(
                        ediGuideline.guidelineUrl
                      )
                    }}
                  >
                    <Table.Data xs={6}>{ediGuideline.guidelineName}</Table.Data>
                  </Table.Row>
                )
              })}
          </Table.Body>
        </Table>
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        <Grid.Item xs={12}>
          <this.registrationApproval />
          <this.ediGuideline />
        </Grid.Item>
      </React.Fragment>
    )
  }
}

export default EdiGuidelineInformation
