import React from 'react'
import UserRoles from './UserRoles'

const AccessControl = (props) => {
  const roleIsPresent = userHasTheRole(props.role, props.env)
  if (roleIsPresent) {
    return props.children
  } else {
    return <React.Fragment />
  }
}
export const userHasTheRole = (requiredRoles, env) => {
  var currentUserRoles = UserRoles(env)
  if (currentUserRoles) {
    var currentUserRole
    for (currentUserRole of currentUserRoles) {
      var requiredRole
      for (requiredRole of requiredRoles) {
        if (requiredRole === currentUserRole) {
          return true
        }
      }
    }
  }
  return false
}

export default AccessControl
