export const REGISTRATION_STEP_1_ID = '1'
export const REGISTRATION_STEP_2_ID = '2'
export const REGISTRATION_STEP_3_ID = '3'
export const REGISTRATION_STEP_4_ID = '4'
export const REGISTRATION_STEP_5_ID = '5'
export const REGISTRATION_STEP_6_ID = '6'
export const REGISTRATION_STEP_7_ID = '7'
export const REGISTRATION_STEP_1_LABEL = 'Vendor Selection'
export const REGISTRATION_STEP_2_LABEL = 'Contact Info'
export const REGISTRATION_STEP_3_LABEL = 'EDI Partner Info'
export const REGISTRATION_STEP_4_LABEL = 'Service Provider Selection'
export const REGISTRATION_STEP_5_LABEL = 'Registration Type Selection'
export const REGISTRATION_STEP_6_LABEL = 'Registration Confirmation'
export const REGISTRATION_STEP_7_LABEL = 'Registration Submitted'
export const LOGGER_LEVEL_INFO = 'INFO'
export const LOGGER_LEVEL_ERROR = 'ERROR'
export const OPERATING_COMPANY_DVS = 5
