import React from 'react'
import axios from 'axios'
import { trackPromise } from 'react-promise-tracker'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Heading, Grid } from '@enterprise-ui/canvas-ui-react'
import * as GlobalConstants from './common/Constants'
import { LOGGER_LEVEL_INFO, LOGGER_LEVEL_ERROR } from './common/Constants'
import Logger from '../../global/util/Logger'
import RegistrationSummary from './RegistrationSummary'
import PurchaseOrderFormatSelection from './PurchaseOrderFormatSelection'

class RegistrationConfirmation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      registrationTypesDoc: [],
    }
    this.handleConfirmationContinue = this.handleConfirmationContinue.bind(this)
    this.submitRegistration = this.submitRegistration.bind(this)
  }

  render() {
    return (
      <React.Fragment>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="RegistrationConfirmationPanel"
          >
            <Heading size={5}>Registration Confirmation</Heading>
          </AccordionSummary>
          <AccordionDetails>
            <Grid.Container>
              <RegistrationSummary
                {...this.props}
                operatingCompany={this.props.vendorDetails.operatingCompany}
                vendorName={
                  this.props.vendorDetails.vendor.vendor_gvi.vendor_name
                }
                vendorNumber={
                  this.props.vendorDetails.vendor.vendor_gvi.gms_vendor_id
                }
                isTransportation={
                  this.props.vendorDetails.isTransportationVendor
                }
                scacCode={
                  this.props.vendorDetails.isTransportationVendor
                    ? this.props.vendorDetails.companyId
                    : ''
                }
                ecomVendorNumber={
                  this.props.vendorDetails.operatingCompany ===
                  GlobalConstants.OPERATING_COMPANY_DVS
                    ? this.props.vendorDetails.vendor.vendor_shippings[0]
                        .ecom_vendor_id
                    : '0'
                }
                ediId={
                  this.props.userRegistration.ediId2 &&
                  this.props.userRegistration.ediId2.length > 0
                    ? this.props.userRegistration.ediId2
                    : this.props.userRegistration.ediId
                }
                qualifier={
                  this.props.userRegistration.qualifier2 &&
                  this.props.userRegistration.qualifier2.length > 0
                    ? this.props.userRegistration.qualifier2
                    : this.props.userRegistration.qualifier
                }
                registrationTypeName={
                  this.props.selectedRegistrationType?.registrationTypeName
                }
                returnRegistrationDocuments
                getRegistrationTypeDocuments={(registrationTypeDocuments) => {
                  this.setState({
                    registrationTypesDoc: registrationTypeDocuments,
                  })
                }}
              />
              <Grid.Item xs={8}>
                <Grid.Container>
                  {!this.state.err ? (
                    <PurchaseOrderFormatSelection
                      {...this.props}
                      handleConfirmationContinue={
                        this.handleConfirmationContinue
                      }
                    />
                  ) : (
                    <p className="ess_error" id="pending_registration_error">
                      This registration can’t be completed because our records
                      indicate there is a pending registration for this vendor
                      number. <br />
                      <br />
                      The details of the pending registration are:
                      <br />
                      EDI ID: {
                        this.state.pendingRegistrations.ediPartnerId
                      }{' '}
                      <br />
                      Qualifier:{' '}
                      {this.state.pendingRegistrations.ediPartnerQualifier}
                      <br />
                      <br />
                      If you have questions or need further assistance, please
                      contact Target’s Electronic Commerce Service Center at{' '}
                      {this.props.env.ecsc.contact}.
                    </p>
                  )}
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    )
  }

  submitRegistration(selectedPurchaseOrderFormat) {
    var userRegistration = {}
    userRegistration.serviceProvider = this.props.selectedServiceProvider
    userRegistration.registrationType = this.props.selectedRegistrationType
    userRegistration.ediId = this.props.ediId
    userRegistration.qualifier = this.props.qualifier
    userRegistration.operatingCompany = this.props.operatingCompany
    userRegistration.purchaseOrderFormat = selectedPurchaseOrderFormat
    userRegistration.registrationTypeDocuments = this.state.registrationTypesDoc
    userRegistration.userLanId = this.props.userInfo.lanId
    userRegistration.userFirstName = this.props.userInfo.firstName
    userRegistration.userLastName = this.props.userInfo.lastName
    userRegistration.userEmailId = this.props.userInfo.email
    userRegistration.userRoles = this.props.userRoles
    userRegistration.companyId = this.props.vendorDetails.companyId
    userRegistration.vendorName =
      this.props.vendorDetails.vendor.vendor_gvi.vendor_name
    userRegistration.gmsVendorNumber =
      this.props.vendorDetails.vendor.vendor_gvi.gms_vendor_id
    userRegistration.isTransportationVendor =
      this.props.vendorDetails.isTransportationVendor
    if (this.props.vendorDetails.isTransportationVendor === true) {
      userRegistration.scacCode = this.props.vendorDetails.companyId
    }

    userRegistration.businessPartnerContact =
      this.props.selectedBusinessPartnerContact
    const requestOptions = {
      method: 'post',
      url: this.props.env.ess.url + '/api/v1/submit-edi-registration',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(userRegistration),
    }
    Logger(
      'RegistrationConfirmation - registration being submitted with request options: ' +
        requestOptions.body,
      LOGGER_LEVEL_INFO,
      this.props.env.ess.url + '/api/v1/submit-edi-registration'
    )
    trackPromise(
      axios(requestOptions)
        .then((response) => {
          var data = response.data
          console.log('Edi Registration Submission: ' + JSON.stringify(data))
          if (data.errorCode !== 'E001') {
            this.props.handleConfirmationContinue(true)
            Logger(
              'RegistrationSubmitted',
              LOGGER_LEVEL_INFO,
              this.props.env.ess.url + '/api/v1/submit-edi-registration'
            )
          } else {
            this.setState({
              err: true,
            })
          }
        })
        .catch((error) => {
          this.setState({ error, isLoading: false })
          if (error.includes('Error')) {
            console.log('Reg Error')
          }
          Logger(
            'RegistrationSubmitted',
            LOGGER_LEVEL_ERROR,
            this.props.env.ess.url + '/api/v1/submit-edi-registration'
          )
          throw new Error(
            'Unexpected error occurred while calling the endpoint --> /api/v1/submit-edi-registration'
          )
        })
    )
  }

  handleConfirmationContinue(
    registrationConfirmed,
    selectedPurchaseOrderFormat
  ) {
    console.log('*******Confirm called*******')
    this.setState({
      registrationConfirmed: registrationConfirmed,
    })
    this.submitRegistration(selectedPurchaseOrderFormat)
  }
}

export default RegistrationConfirmation
