import { useAuth } from '@praxis/component-auth'

const UserRoles = () => {
  const auth = useAuth()
  const enableTestLogins = localStorage.getItem('testLoginUserDetails')
    ? true
    : false
  if (!enableTestLogins && auth?.isAuthenticated()) {
    return auth.session.userInfo.memberOf
  } else {
    console.log('getting roles from testLoginUserDetails')
    const testLoginUser = JSON.parse(
      localStorage.getItem('testLoginUserDetails')
    )
    console.log('test login user: ' + JSON.stringify(testLoginUser))
    if (testLoginUser) {
      return testLoginUser.memberOf
    }
  }
}
export default UserRoles
