const commonConfig = {
  environment: 'local',
  auth: {
    // authorizationUrl:
    //   'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
    // logoutUrl: `https://oauth.iam.perf.partnersonline.com/login/responses/logoff.html?target=${window.location.origin}/`,
    authorizationUrl:
      'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
    logoutUrl:
      'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
    clientId: 'ess_ui_dev_npe_im',
  },
  legacy: {
    polUrl: 'https://www.partnersonline.com',
    legacyPssUrl: 'https://edi.partnersonline.com/edi',
  },
  ess: {
    url: 'http://localhost:8080/edi_selfservice_registration',
  },
  ecsc: {
    contact:
      '612-304-3310 option 1, M-F, 7 a.m. - 5 p.m. CT or email: EDI.helpdesk@target.com',
  },
  pol: {
    copyrightLabel: '© 2021 Target. All Rights Reserved.',
    copyrightLink: '/page/public/legal/Copyright',
  },
  internalUserRole: 'APP-BEX-USERS',
  externalUserRole: 'APP-BEX',
  helpDeskAdminRole: 'APP-BEX-HELPDESK-ADMIN-STG',
  supportAdminRole: 'APP-BEX-SUPPORT-ADMIN-STG',
}

export default commonConfig
