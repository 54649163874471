import Home from './Home'
import { EnvProvider } from '@praxis/component-runtime-env'
import { AuthProvider } from '@praxis/component-auth'
import 'typeface-roboto'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-datepicker'
import commonConfig from './commonConfig'

export const App = (props) => {
  return (
    <EnvProvider
      commonConfig={commonConfig}
      configPath={
        process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'dev'
          ? '/config.json'
          : '/app-environment'
      }
    >
      {({ env }) => {
        //console.log('env: ' + process.env.REACT_APP_ENV)
        return (
          <AuthProvider
            {...env.auth}
            shouldSendHeaders={() => true}
            loginRedirect={`${window.location.origin}`}
          >
            <Home id="root" env={env} {...props} />
          </AuthProvider>
        )
      }}
    </EnvProvider>
  )
}

export default App
