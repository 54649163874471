import { logEvent } from '@praxis/component-logging'

function Logger(message, logLevel, url) {
  const optionalLoggingOptions = {
    level: logLevel,
  }
  let loggingInfo = {
    message: message,
    url: url,
  }

  logEvent(loggingInfo, optionalLoggingOptions)
}

export default Logger
