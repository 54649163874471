import React from 'react'
import axios from 'axios'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import EdiGuidelineInformation from './EdiGuidelineInformation'

class RegistrationSubmittedPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ediGuideline: [],
    }
  }

  /* Loads Edi Guideline Data from api when the component renders*/
  componentDidMount() {
    console.log(
      'registration submission registrationTypeId: ' +
        this.props.selectedRegistrationType.registrationTypeId
    )
    const EDI_GUIDELINE_ENDPOINT =
      this.props.env.ess.url +
      '/api/v1/edi-guideline?registrationTypeId=' +
      this.props.selectedRegistrationType.registrationTypeId
    axios
      .get(EDI_GUIDELINE_ENDPOINT)
      .then((response) => {
        var data = response.data
        console.log(
          'EDI Guideline data response from service:' + JSON.stringify(data)
        )
        this.setState({
          ediGuideline: data,
        })
      })
      .catch((error) => {
        this.setState({ error: true, isLoading: false })
        console.log(
          'Error occurred when getting edi guideline. error: ' + error
        )
      })
  }

  render() {
    return (
      <React.Fragment>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Heading size={5}>Registration Submitted</Heading>
          </AccordionSummary>
          <AccordionDetails>
            <Grid.Container>
              <Grid.Item xs={8}>
                <Grid.Container>
                  <EdiGuidelineInformation
                    {...this.props}
                    ediGuideline={this.state.ediGuideline}
                  />
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    )
  }
}

export default RegistrationSubmittedPanel
