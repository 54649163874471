import React from 'react'
import * as moment from 'moment'
import { ArrowUpward, ArrowDownward } from '@material-ui/icons'
import MaterialTable from 'material-table'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronRight from '@material-ui/icons/ChevronRight'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import { useNavigate } from 'react-router-dom'
import '../../global/css/MaterialTable.css'
import * as MaintenanceConstants from './Constants'

export default function RegistrationStatusResultsTable(props) {
  let history = useNavigate()
  const tableIcons = {
    ArrowDownward: ArrowDownward,
    ArrowUpward: ArrowUpward,
    SortArrow: ArrowUpward,
    FirstPage: FirstPage,
    LastPage: LastPage,
    NextPage: ChevronRight,
    PreviousPage: ChevronLeft,
  }
  return (
    <React.Fragment>
      <MaterialTable
        id="registration_search_results"
        icons={tableIcons}
        options={{
          search: false,
          sorting: true,
          paging: true,
          pageSize: 10,
        }}
        tableLayout="auto"
        onRowClick={(event, rowData) => {
          console.log('Row Clicked')
          console.log(rowData)
          history(
            '/registration/details?registrationId=' + rowData.registrationGenId
          )
        }}
        columns={[
          {
            title: ' Company ID',
            field: 'companyId',
            cellStyle: {
              paddingLeft: '12px',
            },
            headerStyle: {
              paddingLeft: '12px',
            },
          },
          {
            title: ' Operating Company',
            field: 'operatingCompany',
            cellStyle: {
              width: 80,
              minWidth: 40,
            },
            headerStyle: {
              width: 80,
              maxWidth: 40,
            },
            render: (rowData) => (
              <span>
                {rowData.operatingCompany ===
                MaintenanceConstants.OPERATING_COMPANY_TARGET_VALUE
                  ? MaintenanceConstants.OPERATING_COMPANY_TARGET
                  : ''}
                {rowData.operatingCompany ===
                MaintenanceConstants.OPERATING_COMPANY_IMPORT_VALUE
                  ? MaintenanceConstants.OPERATING_COMPANY_IMPORT
                  : ''}
                {rowData.operatingCompany ===
                MaintenanceConstants.OPERATING_COMPANY_DVS_VALUE
                  ? MaintenanceConstants.OPERATING_COMPANY_DVS
                  : ''}
              </span>
            ),
          },
          { title: 'Company Name', field: 'partnerName' },
          {
            title: 'Date Submitted',
            field: 'createTimeStamp',
            type: 'numeric',
            align: 'left',
            defaultSort: 'desc',
            render: (rowData) => (
              <span>
                {moment(rowData.createTimeStamp).format('MM-DD-YYYY')}
              </span>
            ),
          },
          { title: 'Partner ID', field: 'ediPartnerId' },
          { title: 'Qualifier', field: 'ediPartnerQualifier' },
          {
            title: 'Type',
            field: '',
            render: (rowData) => <span>EDI</span>,
          },
          {
            title: 'Registration Status',
            field: 'registrationStatus',
          },
        ]}
        data={props.registrationSearchResults}
        title=""
      />
    </React.Fragment>
  )
}
