import React from 'react'
import { Table, Heading, Divider } from '@enterprise-ui/canvas-ui-react'

function PartnerProfileDetails(props) {
  return (
    <React.Fragment>
      <Heading size={5} className="hc-pt-dense hc-pb-dense">
        Based on the registration type selected, the following partner profile
        details will be setup as follows:
      </Heading>
      <Divider />
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header xs={6} className="table-header-font">
              Mandatory Documents
            </Table.Header>
            <Table.Header xs={2} className="table-header-font">
              Version
            </Table.Header>
            <Table.Header xs={2} className="table-header-font">
              Direction
            </Table.Header>
            <Table.Header xs={2} className="table-header-font">
              Testing
            </Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {props.registrationTypesDoc &&
            props.registrationTypesDoc.map((registrationTypeDoc) => {
              return (
                <Table.Row key={registrationTypeDoc.registrationTypeDocGenId}>
                  <Table.Data xs={6} className="table-data-font">
                    {registrationTypeDoc.documentNumber} {' - '}
                    {registrationTypeDoc.documentName}
                  </Table.Data>
                  <Table.Data xs={2} className="table-data-font">
                    {registrationTypeDoc.documentVersion}
                  </Table.Data>
                  <Table.Data xs={2} className="table-data-font">
                    {registrationTypeDoc.documentDirection}
                  </Table.Data>
                  <Table.Data xs={2} className="table-data-font">
                    {registrationTypeDoc.testingRequired}
                  </Table.Data>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
    </React.Fragment>
  )
}

export default PartnerProfileDetails
